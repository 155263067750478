import { useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { IconUpload, IconClose } from 'anchor-ui/icons';
import Button from 'anchor-ui/button';
import defaultAvatar from "./../../../assets/default_avatar.jpg";

const EditAvatarPopover = ({
  open,
  setOpen,
  setAlert,
  user,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>
  user: CometChat.User;
}) => {
  const [image, setImage] = useState<string | null>(null);

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) { // 2 MB limit
        setAlert({ message: 'De afbeelding mag niet groter zijn dan 2 MB.', type: 'error' });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result as string;
        try {
          const response = await CometChat.callExtension(
            'avatar',
            'POST',
            'v1/upload',
            {
              avatar: base64String,
            }
          );
          const avatarURL = (response as any).avatarURL;
          user.setAvatar(avatarURL);
          setImage(avatarURL);

          setAlert({ message: 'Afbeelding succesvol geüpdatet.', type: 'success' });
          setOpen(false);
        } catch (error) {
          console.error('Error uploading image:', error);

          const code = (error as any).code;
          if (code === 'ERR_INVALID_FILE') {
            setAlert({ message: 'Ongeldig bestandstype.', type: 'error' });
          } else {
            setAlert({ message: 'Er is iets fout gegaan bij het uploaden van de afbeelding.', type: 'error' });

          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    const fileInput = document.getElementById('avatar-upload') as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <section className={`white-page-popover edit-avatar-popover ${!open ? 'closed' : ''}`}>
      <div>
        <h1>
          Wijzig profielafbeelding<br />
          (max 2mb)
        </h1>

        <Button
          iconButton
          style={{
            fontSize: '16px',
            position: 'absolute',
            top: '8px',
            right: '8px',
            backgroundColor: 'rgba(21, 21, 21, 0.2)'
          }}
          onClick={() => setOpen(false)}
        >
          <IconClose color='white' />
        </Button>

        <section
          data-radium="true"
          className="edit-avatar-section"
          style={{ backgroundImage: `url(${image ?? user.getAvatar() ?? defaultAvatar})` }}
        >
          <input
            type="file"
            accept=".jpg,.jpeg,.png,.svg"
            id="avatar-upload"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
          <Button iconButton component="span" onClick={handleButtonClick}>
            <IconUpload color={"white"} />
          </Button>
        </section>
      </div>
    </section>
  );
};

export default EditAvatarPopover;