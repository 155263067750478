import Switch from 'anchor-ui/switch';

const PrivacyPage = ({
  isPrivate,
  changeVisiblity,
}: {
  isPrivate: boolean;
  changeVisiblity: (bool: boolean) => void;
}) => {
  
  return (
    <div>
      <h2>Privacy</h2>

      <Switch
        label='Publiek profiel'
        active={isPrivate === false}
        toggleSwitch={() => changeVisiblity(!isPrivate)}
      />

      <p>Andere gebruikers kunnen jouw profiel {isPrivate && 'niet '}bekijken op www.chatplaza.com.</p>

    </div>
  );
}

export default PrivacyPage;