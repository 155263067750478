import React from 'react';
import { CometChat } from '@cometchat-pro/chat';
import ChannelHeader from 'anchor-ui/channel-header';
import Card from 'anchor-ui/card';
import Profile from 'anchor-ui/profile';
import Button from 'anchor-ui/button';
import CardHeader from 'anchor-ui/card-header';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import Avatar from 'anchor-ui/avatar';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import FriendButtons from './../../components/LeftSideBarMenu/FriendsListComponent/FriendButtons';
import ConversationsListComponent from './../../components/LeftSideBarMenu/ConversationListComponent';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import ToggleLeftMenuButton from '../ToggleLeftMenuButton';
import './styles.css';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import GoogleAdSense from '../GoogleAdSense';
import { Functions } from 'firebase/functions';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import blogData from "./../BlogPage/Blogs/articles.json";

const Timeline = ({
  loggedInUser,
  functions,
  friends,
  showUserProfile,
  startPrivateChat,
  setUserToReport,
  setFriendToDelete,
  blockedUsers,
  handleBlockUser,
  conversations,
  unreadCount,
  handleConversationClick,
  handleGroupConversationClick,
  initialConversation,
  currentChat,
  setCurrentChat,
  refreshConversations,
  setUnreadCount,
  setAlert,
  leftMenuOpen,
  setLeftMenuOpen,
  userSettings,
  removeConversation,
  setRemoveConversation,
}: {
  loggedInUser: CometChat.User;
  functions: Functions;
  friends: CometChat.User[];
  showUserProfile: (user: CometChat.User) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  conversations: ConversationInterface[];
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleConversationClick: (conversation: CometChat.Conversation) => void;
  handleGroupConversationClick: (group: CometChat.Group) => Promise<void>;
  initialConversation: CometChat.Conversation | null;
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  refreshConversations: () => void;
  setUnreadCount: React.Dispatch<React.SetStateAction<{
    [id: string]: { nr: number; chat: ActiveChannelInterface; };
  }>>;
  setAlert: (alert: { message: string, type: string } | null) => void;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userSettings: UserSettingsInterface;
  removeConversation: any | null;
  setRemoveConversation: React.Dispatch<React.SetStateAction<any | null>>;
}) => {

  const onlineFriends = friends.filter(friend => friend.getStatus() === 'online');
  return (
    <div className='timeline-container'>
      <ChannelHeader
        name="Tijdlijn"
        style={{ lineHeight: '18px' }}
        leftButton={
          <ToggleLeftMenuButton
            leftMenuOpen={leftMenuOpen}
            setLeftMenuOpen={setLeftMenuOpen}
            unreadCount={unreadCount}
          />
        }
      />

      <div className='timeline-top'>
        <Card style={{ height: '100%', width: '100%', backgroundColor: 'white' }}>
          <GoogleAdSense
            adSlot="8285598752"
            style={{ display: 'inline-block', width: "984px", height: "121px" }}
          />
        </Card>
      </div>

      <div className='timeline-content'>
        <div className='timeline-side-card'>
          <Card style={{ height: '100%', backgroundColor: 'white' }}>
            <GoogleAdSense
              adSlot="9999904289"
              style={{ display: 'inline-block', width: '200px', height: '750px' }}
            />
          </Card>
        </div>

        <section className='timeline-section'>
          <div className='timeline-card timeline-card-left'>
            <Card>
              <Profile
                header={loggedInUser.getName()}
                button={
                  <Button onClick={() => setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser }))}>
                    Bekijk Profiel
                  </Button>
                }
                avatar={loggedInUser.getAvatar() ?? defaultAvatar}
                coverImage={loggedInUser.getAvatar() ?? defaultAvatar}
                coverImageStyle={{ backgroundColor: userSettings.themeColor }}
                style={{ paddingBottom: '16px' }}
              />
            </Card>

            <div className='timeline-conversations'>
              <Card style={{ height: '100%', backgroundColor: 'white', marginTop: '16px' }}>
                <ConversationsListComponent
                  conversations={conversations}
                  unreadCount={unreadCount}
                  handleConversationClick={handleConversationClick}
                  handleGroupConversationClick={handleGroupConversationClick}
                  initialConversation={initialConversation}
                  currentChat={currentChat}
                  setCurrentChat={setCurrentChat}
                  friends={friends}
                  refreshConversations={refreshConversations}
                  setUnreadCount={setUnreadCount}
                  loggedInUser={loggedInUser}
                  blockedUsers={blockedUsers}
                  setAlert={setAlert}
                  isTimeline
                  functions={functions}
                  userSettings={userSettings}
                  removeConversation={removeConversation}
                  setRemoveConversation={setRemoveConversation}
                />
              </Card>
            </div>
          </div>

          <div className='timeline-card timeline-middle-card'>
            <Card style={{ height: '100%', backgroundColor: 'white' }}>
              <GoogleAdSense
                adSlot="7324535835"
                style={{ display: 'inline-block', width: '352px', height: '278px' }}
              />
            </Card>

            <Card style={{ height: '450px', backgroundColor: 'white', marginTop: "16px" }}>
              <CardHeader title="Laatste nieuws" />
              <a className="blog-card timeline" href={`/blog/${blogData[0].url}`} target="_blank">
                <img src={blogData[0].img} alt="Blog" />
                <div className="blog-card-content">
                  <h3>{blogData[0].title}</h3>
                  <p>
                    {blogData[0].content.intro.length > 100 ? blogData[0].content.intro.substring(0, 100) + '...' : blogData[0].content.intro}
                  </p>
                  <small>{blogData[0].publicationDate}</small>
                </div>
              </a>
            </Card>
          </div>

          <div className='timeline-card timeline-friends'>
            <Card style={{ height: '100%', backgroundColor: 'white' }}>
              <CardHeader title={`Online vrienden (${onlineFriends.length})`} />
              <List>
                {onlineFriends.map((friend, index) => (
                  <ListItem
                    key={index}
                    primaryText={friend.getName()}
                    secondaryText={getGenderFromMetadata(friend)}
                    avatar={
                      <Avatar
                        image={friend.getAvatar() ?? defaultAvatar}
                        style={{ backgroundColor: userSettings.themeColor }}
                        status={checkIfUserIsBlocked(blockedUsers, friend.getUid()) ? null : friend.getStatus()}
                      />
                    }
                    blocked={checkIfUserIsBlocked(blockedUsers, friend.getUid())}
                    rightButton={
                      <FriendButtons
                        friend={friend}
                        showUserProfile={showUserProfile}
                        startPrivateChat={startPrivateChat}
                        setUserToReport={setUserToReport}
                        setFriendToDelete={setFriendToDelete}
                        blockedUsers={blockedUsers}
                        handleBlockUser={handleBlockUser}
                      />
                    }
                  />
                ))}
              </List>
            </Card>

            <div className='timeline-card timeline-bottom-right'>
              <Card style={{ height: '100%', backgroundColor: 'white' }}>
                <GoogleAdSense
                  adSlot="4284543179"
                  style={{ display: 'inline-block', width: '300px', height: '240px' }}
                />
              </Card>
            </div>
          </div>
        </section>

        <div className='timeline-side-card'>
          <Card style={{ height: '100%', backgroundColor: 'white' }}>
            <GoogleAdSense
              adSlot="6426387134"
              style={{ display: 'inline-block', width: '200px', height: '750px' }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Timeline;