import { CometChat } from '@cometchat-pro/chat';
import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import './styles.css';
import { IconClose, IconEdit } from 'anchor-ui/icons';
import './../userProfile.css';
import { metadataInterface } from './../../../interfaces/metadataInterface';
import AccountDetailsSection from './AccountDetailsSection';
import { useEffect, useRef, useState } from 'react';
import { getActiveColor } from './../../../utils/activeColor';
import ToggleLeftMenuButton from '../../ToggleLeftMenuButton';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';
import EditAvatarPopover from './EditAvatarPopover';
import { getProfileFromMetadata, updateUserMetadata } from '../../../utils/updateMetadata';
import { Functions, httpsCallable } from 'firebase/functions';
import { AccountDetailItemProps } from '../../../interfaces/accountDetailItemProps';

const LoggedInUserProfile = ({
  user,
  setCurrentChat,

  leftMenuOpen,
  setLeftMenuOpen,
  unreadCount,

  setAlert,
  functions,
}: {
  user: CometChat.User;
  setCurrentChat: (chat: any) => void;

  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>
  functions: Functions
}) => {
  const [editAvatarOpen, setEditAvatarOpen] = useState(false);

  const [generalItems, setGeneralItems] = useState<{ label: string, value: string }[]>([]);
  const [detailsItems, setDetailsItems] = useState<AccountDetailItemProps[]>([]);
  const [appearanceItems, setAppearanceItems] = useState<AccountDetailItemProps[]>([]);
  const [backgroundImg, setBackgroundImg] = useState<string | null>(null);
  const userMetadata = getProfileFromMetadata(user);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const updateUserProfile = (userProfile: metadataInterface) => {
    const generalItems = [
      { label: 'Gebruikersnaam', value: user.getName() },
      { label: 'Geboortedatum', value: userProfile.about?.birthDate + '' },
      { label: 'Geslacht', value: userMetadata.about.gender ?? "anders" },
    ];

    const detailsItems = [
      {
        label: 'Over mij',
        value: userProfile.about.aboutText ?? '',
        addText: 'Schrijf iets over jezelf',
        inputType: 'textarea',
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, aboutText: value } })
      },
      {
        label: 'Relatie status',
        value: userProfile.about.relationshipStatus ?? '',
        addText: 'Voeg jouw relatie status toe',
        inputType: 'select',
        selectOptions: ['Single', 'In een relatie', 'Getrouwd', 'In een open relatie'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, relationshipStatus: value } })
      },
      {
        label: 'Functie',
        value: userProfile.about.function ?? '',
        addText: 'Voeg je functie toe',
        inputType: 'text',
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, about: { ...userProfile.about, function: value } })
      },
    ];

    const appearanceItems = [
      {
        label: 'Bouw',
        value: userProfile.appearance?.build ?? '',
        addText: 'Voeg jouw bouw toe',
        inputType: 'select',
        selectOptions: ['Atletisch', 'Gemiddeld', 'Curvy', 'Fit', 'Overgewicht', 'Dun', 'Anders'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, build: value } })
      },
      {
        label: 'Kleur haar',
        value: userProfile.appearance?.hairColor ?? '',
        addText: 'Voeg jouw haarkleur toe',
        inputType: 'select',
        selectOptions: ['Zwart', 'Blond', 'Bruin', 'Grijs', 'Rood', 'Wit', 'Anders'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, hairColor: value } })
      },
      {
        label: 'Kleur ogen',
        value: userProfile.appearance?.eyeColor ?? '',
        addText: 'Voeg jouw oogkleur toe',
        inputType: 'select',
        selectOptions: ['Zwart', 'Blauw', 'Bruin', 'Groen', 'Grijs', 'Hazelnoot', 'Meerdere kleuren', 'Anders'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, eyeColor: value } })
      },
      {
        label: 'Piercings',
        value: userProfile.appearance?.piercings ?? '',
        addText: 'Voeg jouw piercings toe',
        inputType: 'select',
        selectOptions: ['Veel', 'Een paar', 'Geen'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, piercings: value } })
      },
      {
        label: 'Tattoos',
        value: userProfile.appearance?.tattoos ?? '',
        addText: 'Voeg jouw tattoos toe',
        inputType: 'select',
        selectOptions: ['Veel', 'Een paar', 'Geen'],
        onSave: (value: string) => handleUpdateUserMetadata({ ...userProfile, appearance: { ...userProfile.appearance, tattoos: value } })
      },
    ];

    setGeneralItems(generalItems);
    setDetailsItems(detailsItems);
    setAppearanceItems(appearanceItems);
  };

  const fetchUserDetails = async () => {
    const fetchUserProfile = httpsCallable(functions, 'fetchUserProfile');
    const userProfileResult = await fetchUserProfile({ uid: user.getUid() });
    const userProfile = userProfileResult.data as unknown as metadataInterface;

    userProfile.about.gender = userMetadata.about.gender;
    updateUserProfile(userProfile);
  };

  const handleUpdateUserMetadata = async (newMetadata: metadataInterface) => {
    const updatedMetadata = await updateUserMetadata(functions, newMetadata, user);

    // if (updatedMetadata) { also check if its a metadatainterface
    if (updatedMetadata && typeof updatedMetadata === 'object') {
      updateUserProfile(updatedMetadata);
      return true;
    } else {
      setAlert({ message: 'Er is iets misgegaan bij het updaten van je profiel', type: 'error' });
      return false;
    }
  };

  const accountItems = [
    { label: 'Geverifieerd account', value: 'Ja' },
  ];

  const closeProfile = () => {
    setCurrentChat((prevState: any) => {
      return {
        ...prevState,
        userProfile: null,
      }
    });
  };

  const handleChangeProfileBannerImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    // Create a new MediaMessage with admin_user as receiver and image type
    const mediaMessage = new CometChat.MediaMessage(
      'admin_user',
      file,
      CometChat.MESSAGE_TYPE.IMAGE,
      CometChat.RECEIVER_TYPE.USER
    );

    // Add the profile_banner_img tag
    mediaMessage.setTags(["profile_banner_img"]);

    CometChat.sendMediaMessage(mediaMessage).then(
      message => {
        if (message instanceof CometChat.MediaMessage) {
          setAlert({ message: 'Bannerafbeelding is aangepast.', type: 'success' });
          setBackgroundImg(message.getURL());
        }
      },
      error => {
        console.error('Error sending image:', error);
        setAlert({ message: 'Er is iets mis gegaan', type: 'error' });
      }
    );
  };

  const fetchBannerImg = async () => {
    const fetchBannerImg = httpsCallable(functions, 'fetchProfileBannerImg');
    const result = await fetchBannerImg({ onBehalfOf: user.getUid() });

    const data = result.data as { imageUrl?: string };
    if (data.imageUrl) {
      setBackgroundImg(data.imageUrl as string);
      return;
    }
  };

  const handleImageClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
  
    handleChangeProfileBannerImg(event);

    // Reset the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    fetchUserDetails();
    fetchBannerImg();
  }, []);

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />

      <EditAvatarPopover
        open={editAvatarOpen}
        setOpen={setEditAvatarOpen}
        setAlert={setAlert}
        user={user}
      />

      <Profile
        header={user.getName()}
        avatar={user.getAvatar() ?? defaultAvatar}
        coverImage={backgroundImg ?? user.getAvatar() ?? defaultAvatar}
        coverImageStyle={{ backgroundColor: getActiveColor() }}
        style={{
          width: '100%',
        }}
        avatarStyle={{
          width: '156px',
          height: '156px',
          left: '102px',
        }}
        headerStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '200px'
        }}
        secondaryTextStyle={{
          width: 'auto',
          position: 'absolute',
          textAlign: 'left',
          left: '196px',
          top: '254px'
        }}
        button={
          <>
            <button
              type="button"
              data-radium="true"
              className="edit-avatar-button"
              onClick={() => setEditAvatarOpen(true)}
            >
              <IconEdit color={'white'} />
            </button>

            <ToggleLeftMenuButton
              leftMenuOpen={leftMenuOpen}
              setLeftMenuOpen={setLeftMenuOpen}
              unreadCount={unreadCount}
              isProfile
            />

            <Button
              iconButton
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '8px',
                right: '8px',
                backgroundColor: 'rgba(21, 21, 21, 0.2)'
              }}
              onClick={closeProfile}
            >
              <IconClose color='white' />
            </Button>

            <Button
              iconButton
              style={{
                fontSize: '16px',
                position: 'absolute',
                top: '52px',
                right: '8px',
                backgroundColor: 'rgba(21, 21, 21, 0.2)'
              }}
              onClick={handleImageClick}
            >
              <IconEdit color='white' />
            </Button>
          </>
        }
      >
        <div className="profile-card-container profile-width loggedin-profile">
          <AccountDetailsSection title="Algemeen" items={generalItems} />
          <AccountDetailsSection title="Details" items={detailsItems} />
          <AccountDetailsSection title="Uiterlijk" items={appearanceItems} />
          <AccountDetailsSection title="Account" items={accountItems} />
        </div>
      </Profile>
    </>
  );
}

export default LoggedInUserProfile;