import { useEffect, useRef, useState } from 'react';
import { CometChat } from '@cometchat-pro/chat';

import AppHeader from 'anchor-ui/app-header';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import Badge from 'anchor-ui/badge';
import Divider from 'anchor-ui/divider';
import Avatar from 'anchor-ui/avatar';
import Input from 'anchor-ui/input';
import Button from 'anchor-ui/button';

import {
  IconSearch,
  IconNotification,
  IconExit,
  IconMore,
  IconAddFriend,
  IconChannels,
  IconConversation,
  IconPerson,
  IconHome,
  IconClose,
  IconSettings,
  IconBriefcase,
} from 'anchor-ui/icons';

import defaultAvatar from "./../../assets/default_avatar.jpg";
import logosvg from './../../assets/chatplaza.com.svg';
import NoResultsIcon from './../../assets/NoResultsIcon';

import { NotificationInterface } from './../../interfaces/notificationInterface';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';

import './header.css';
import LogoutDialog from './LogoutDialog';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import { getActiveColor } from '../../utils/activeColor';
import { checkIfUserIsAdmin, checkIfUserIsSuperAdmin } from '../../utils/checkIfUserIsAdmin';
import BroadcastModal from '../BroadcastModal';
import { Functions } from 'firebase/functions';

interface Props {
  loggedInUser: CometChat.User;
  notifications: NotificationInterface[] | null;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  handleLogout: () => void;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  conversations: ConversationInterface[];
  handleChangeConversation: (conversation: CometChat.Conversation) => void;
  handleChangeGroupConversation: (group: CometChat.Group) => Promise<void>;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  setCurrentChat: (chat: any) => void;
  userSettings: UserSettingsInterface;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}

const Header = ({
  loggedInUser,
  notifications,
  setNotifications,
  handleLogout,
  unreadCount,
  conversations,
  handleChangeConversation,
  handleChangeGroupConversation,
  setActiveTabIndex,
  setCurrentChat,
  userSettings,
  setAlert,
  functions,
}: Props) => {

  const [searchActive, setSearchActive] = useState(false);
  const [responsiveSearchActive, setResponsiveSearchActive] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const responsiveSearchRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
  const [userList, setUserList] = useState<CometChat.User[]>([]);

  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const [broadcastModalOpen, setBroadcastModalOpen] = useState(false);

  const generalFontSize = userSettings.generalFontSize;

  const toggleSearch = () => {
    setSearchActive(!searchActive);
    setResponsiveSearchActive(!responsiveSearchActive);
  };

  const searchUsers = async (searchTerm: string) => {
    // if input is less than 3 characters, don't search
    if (searchTerm.length < 3) {
      setIsSearching(false);
      return;
    }

    setIsSearching(true);
    // search users
    let limit = 30;
    let searchKeyword = searchTerm;
    let usersRequest = new CometChat.UsersRequestBuilder()
      .setLimit(limit)
      .setSearchKeyword(searchKeyword)
      .build();

    await usersRequest.fetchNext().then(
      userList => {
        // Filter out all double usernames
        const newUsers = userList.filter((user, index, self) =>
          index === self.findIndex((t) => (
            t.getUid() === user.getUid()
          ))
        );
        setUserList(newUsers);
      },
      error => {
        console.error("User list fetching failed with error:", error);
      }
    );

    setIsSearching(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchInput(value);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setIsSearching(true);
    setUserList([]);
    setTypingTimeout(setTimeout(() => searchUsers(value), 500));
  };

  const getNotificationMsg = (notification: NotificationInterface) => {
    if (notification.type === 'friendRequest' && 'getName' in notification.payload) {
      return `${notification.payload.getName()} heeft een vriendschapsverzoek gestuurd.`;
    } else if (notification.type === 'groupCreated' && 'customData' in notification.payload) {
      const payload = notification.payload as any;
      return `Je bent toegevoegd aan ${payload.data.customData.group.name}.`;
    } else {
      return `Nieuwe notificatie.`;
    }
  };

  const getTotalUnreadCount = () => {
    return Object.values(unreadCount).reduce((acc, { nr }) => acc + nr, 0) || 0;
  };

  const getNotificationCount = () => {
    return getTotalUnreadCount() + (notifications?.length ?? 0);
  };

  const handleNotificationClick = (notification: NotificationInterface) => {
    if (notification.type === 'groupCreated' && 'customData' in notification.payload) {
      const payload = notification.payload as any;
      const payloadGroup = payload.data.customData.group;
      const group = new CometChat.Group(
        payloadGroup.guid,
        payloadGroup.name,
        payloadGroup.groupType,
      );

      // Type guard to check if a conversation is a group
      const isGroup = (conversation: CometChat.Group | CometChat.Conversation): conversation is CometChat.Group => {
        return 'guid' in conversation;
      };

      // Check if the group is already in the conversations
      const groupExists = conversations.some((conversation: ConversationInterface) => {
        if (isGroup(conversation.conversation)) {
          return conversation.conversation.getGuid() === group.getGuid();
        }
        return false;
      });

      if (groupExists) handleChangeGroupConversation(group);
      else setActiveTabIndex(1); // Switch to 'Gesprekken' tab

      // Remove the notification
      setNotifications(prev => {
        if (!prev) return [];
        return prev.filter(notif => notif !== notification);
      });
    } else if (notification.type === 'friendRequest') {
      setActiveTabIndex(2);

      // Remove all notifications where type='friendRequest'
      setNotifications(prevNotifications => {
        if (!prevNotifications) return [];
        const newNotifications = prevNotifications.filter(notification => notification.type !== 'friendRequest');
        return newNotifications
      });
    }
  };

  const handleOpenProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
    // Close the search dropdown
    setSearchActive(false);
    setResponsiveSearchActive(false);
    setUserList([]);
    searchInputRef.current!.value = '';
    setSearchInput('');
  };

  useEffect(() => {
    if (searchActive && searchInputRef.current) {
      searchInputRef.current.focus();
    } else if (!searchActive) {
      setUserList([]);
      searchInputRef.current!.value = '';
    }
  }, [searchActive]);

  const UnreadMsgMenuItems = () => {

    const handleChangeChat = async (chat: ActiveChannelInterface) => {
      const isGroupOrOpen = chat.isGroup || chat.isOpen;

      if (isGroupOrOpen) {
        handleChangeGroupConversation(new CometChat.Group(
          chat.id,
          chat.name,
          CometChat.GROUP_TYPE.PRIVATE
        ));
        return;
      }
      setActiveTabIndex(1); // Switch to 'Gesprekken' tab

      try {
        if (!chat.receiverId) {
          console.error('No receiverId found for chat:', chat);
          return;
        }
        const conversation = await CometChat.getConversation(
          chat.receiverId,
          chat.isGroup ? CometChat.RECEIVER_TYPE.GROUP : CometChat.RECEIVER_TYPE.USER
        );

        handleChangeConversation(conversation);
      } catch (error) {
        console.error('Error while fetching conversation:', error);
      }
    }

    return (
      <>
        {
          Object.entries(unreadCount)
            .filter(([id, { nr }]) => nr > 0)
            .map(([id, { nr, chat }]) => {
              const isGroupOrOpen = chat.isGroup || chat.isOpen;

              return (
                <MenuItem
                  key={id}
                  text={`Je hebt ${nr} ongelezen bericht${nr > 1 ? 'en' : ''} ${isGroupOrOpen ? 'in' : 'van'} ${chat.name}.`}
                  onClick={() => handleChangeChat(chat)}
                  icon={isGroupOrOpen ? <IconChannels /> : <IconConversation />}
                />
              );
            })
        }
      </>
    );
  };

  const Searching = () => (
    <div className="reveal-content">
      <p>Bezig met zoeken...</p>
    </div>
  );

  const NoResults = () => (
    <div className="reveal-content search-results">
      <div className='search-no-result-icon'>
        <NoResultsIcon />
        <p>Sorry, we hebben niks voor je kunnen vinden.</p>
      </div>
    </div>
  );

  const SearchResults = ({ userList }: { userList: CometChat.User[] }) => (
    <div className="reveal-content search-results">
      <List header={`Users (${userList.length})`}>
        {userList.map((user, index) => (
          <ListItem
            key={index}
            avatar={
              <Avatar
                image={user.getAvatar() ?? defaultAvatar}
                status={user.getStatus()}
              />
            }
            primaryText={user.getName()}
            secondaryText={getGenderFromMetadata(user)}
            onClick={() => handleOpenProfile(user)}
          />
        ))}
      </List>
    </div>
  );

  return (
    <>
      <LogoutDialog
        isOpen={logoutModalOpen}
        setIsOpen={setLogoutModalOpen}
        handleLogout={handleLogout}
      />

      <BroadcastModal
        isOpen={broadcastModalOpen}
        setIsOpen={setBroadcastModalOpen}
        setAlert={setAlert}
        functions={functions}
      />

      <AppHeader
        icon={
          <img src={logosvg}
            alt="Chatplaza"
            className="app-logo"
            onClick={() => setCurrentChat({ id: '', name: '', icon: '', isGroup: false })}
          />
        }
        rightButton={
          <div className="app-header-buttons">
            <span className='header-links'>
              <a href="https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws=" target='_blank'>Dating M/V</a>
              <a href="https://www.pikantcams.nl/nl?pi=chatplaza_v2" target='_blank'>Webcams 18+</a>
            </span>

            <div className={`dropdown search-container ${searchActive ? 'active' : ''}`}
              tabIndex={-1}
              onBlur={e => {
                if (e.relatedTarget === null || !e.currentTarget.contains(e.relatedTarget as Node)) {
                  setSearchActive(false);
                }
              }}
            >
              <button onClick={toggleSearch} className="search-icon-button">
                <IconSearch color="white" />
              </button>
              <input
                ref={searchInputRef}
                onChange={handleInputChange}
                className={`search-input ${searchActive ? 'active' : ''}`}
                type="text"
                placeholder=""
              />
              {searchActive && (
                <>
                  {searchInputRef.current!.value!.length < 3 ? null :
                    isSearching ? (
                      <Searching />
                    ) : !userList.length ? (
                      <NoResults />
                    ) : (
                      <SearchResults userList={userList} />
                    )}
                </>
              )}
            </div>

            <div className="dropdown" tabIndex={-1}>
              <IconMenu
                icon={
                  <>
                    <IconNotification color={'white'} />
                    {getNotificationCount() > 0 &&
                      <Badge
                        value={getNotificationCount()}
                        maxValue={9}
                        inverted
                        style={{ top: 0, right: 0, position: 'absolute' }}
                      />
                    }
                  </>
                }
                header="Notificaties"
                headerStyle={getFontSizeStyle(generalFontSize)}
              >
                <div className={generalFontSize}>
                  {getNotificationCount() ?
                    <>
                      {notifications?.map((notification, index) => (
                        <MenuItem
                          key={index}
                          text={getNotificationMsg(notification)}
                          onClick={() => handleNotificationClick(notification)}
                          icon={<IconAddFriend />}
                        />
                      ))}
                      {
                        getTotalUnreadCount() > 0 &&
                        <UnreadMsgMenuItems />
                      }
                    </> :
                    <MenuItem
                      text="Je hebt geen notificaties."
                      onClick={() => { }}
                      textStyle={getFontSizeStyle(generalFontSize)}
                    />
                  }
                </div>
              </IconMenu>
            </div>

            <div className="dropdown" tabIndex={-1}>
              <IconMenu
                icon={<IconMore color={'white'} />}
              >
                <ListItem
                  avatar={loggedInUser.getAvatar() ?? defaultAvatar}
                  primaryText={loggedInUser.getName()}
                  primaryTextStyle={getFontSizeStyle(generalFontSize)}
                />
                <Divider />
                <MenuItem
                  onClick={() => { setCurrentChat({ id: '', name: '', icon: '', isGroup: false }) }}
                  text="Tijdlijn"
                  icon={<IconHome />}
                  textStyle={getFontSizeStyle(generalFontSize)}
                />
                <MenuItem
                  onClick={() => { setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser, showSettingPage: false })) }}
                  text="Mijn Profiel"
                  icon={<IconPerson />}
                  textStyle={getFontSizeStyle(generalFontSize)}
                />
                <MenuItem
                  onClick={() => { setCurrentChat((prev: any) => ({ ...prev, userProfile: loggedInUser, showSettingPage: true })) }}
                  text="Instellingen"
                  icon={<IconSettings />}
                  textStyle={getFontSizeStyle(generalFontSize)}
                />
                <MenuItem
                  onClick={() => setLogoutModalOpen(true)}
                  text="Log Uit"
                  icon={<IconExit />}
                  textStyle={getFontSizeStyle(generalFontSize)}
                />
                {
                  checkIfUserIsAdmin(loggedInUser) && (
                    <>
                      <Divider />
                      <MenuItem
                        onClick={() => setCurrentChat((prev: any) => ({ ...prev, showBanList: true }))}
                        text="Bekijk banlist"
                        icon={<IconBriefcase color={getActiveColor()} />}
                        textStyle={getFontSizeStyle(generalFontSize)}
                      />
                    </>
                  )
                }
                {/* { Broadcast would only work if we are able to fetch all online users
                  checkIfUserIsSuperAdmin(loggedInUser) && (
                    <>
                      <MenuItem
                        onClick={() => setBroadcastModalOpen(true)}
                        text="Broadcast"
                        icon={<IconBriefcase color={getActiveColor()} />}
                        textStyle={getFontSizeStyle(generalFontSize)}
                      />
                    </>
                  )
                } */}
              </IconMenu>
            </div>
          </div>
        }
        style={searchActive ? { zIndex: 1 } : { zIndex: 0 }}
      />

      <div className={`responsive-search ${responsiveSearchActive ? 'active' : ''}`}>
        <div>
          <h1 className='search-header'>
            Zoek iets
          </h1>

          <Button
            onClick={() => setResponsiveSearchActive(false)}
            iconButton
            className="search-close-button"
          >
            <IconClose />
          </Button>

          <div className='search-icon'>
            <IconSearch />
          </div>

          <Input
            ref={responsiveSearchRef}
            onChange={handleInputChange}
            type="text"
            placeholder=""
            value={searchInput}
          />

          <Divider />

          {searchInput.length < 3 ? <></> : isSearching ? (
            <span>Bezig met zoeken...</span>
          ) : !userList.length ? (
            <div className='responsive-search-no-result-icon'>
              <NoResultsIcon />
              <p>Sorry, we hebben niks voor je kunnen vinden.</p>
            </div>
          ) : (
            <>
              {userList.map((user, index) => (
                <ListItem
                  key={index}
                  avatar={
                    <Avatar
                      image={user.getAvatar() ?? defaultAvatar}
                      status={user.getStatus()}
                    />
                  }
                  primaryText={user.getName()}
                  secondaryText={getGenderFromMetadata(user)}
                  onClick={() => handleOpenProfile(user)}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;