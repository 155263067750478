import React from 'react';
import Avatar from 'anchor-ui/avatar';
import { getFontSizeStyle } from '../../../utils/getFontSizeStyle';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import { getActiveColor } from '../../../utils/activeColor';
import CheckConversationButton from './CheckConversationButton';
import RemoveConversationButton from './RemoveConversationButton';

const ConversationListItem = ({
    getPrimaryText,
    isGroup,
    conv,
    getConversationWith,
    formatDate,
    getLastMessageText,
    getIcon,
    getUserAvatar,
    userSettings,
    handleClick,
    conversation,
    deleteMultipleConversationsMode,
    messageToForward,

    conversationsToDelete,
    conversationsToForwardTo,

    unreadMessages,
    blockedUsers,

    setRemoveConversation,

    isSideNav,
}: {
    getPrimaryText: (name: string) => string;
    isGroup: boolean;
    conv: any;
    getConversationWith: (conv: any) => any;
    formatDate: (date: any) => string;
    getLastMessageText: (conversation: any) => string;
    getIcon: (conv: any) => string;
    getUserAvatar: (conv: any) => string;
    userSettings: any;
    handleClick: (conv: any, isGroup: boolean) => void;
    conversation: any;
    deleteMultipleConversationsMode: boolean;
    messageToForward: any;

    conversationsToDelete: any;
    conversationsToForwardTo: any;

    unreadMessages: number | undefined;
    blockedUsers: any;

    setRemoveConversation: React.Dispatch<React.SetStateAction<any>>;

    isSideNav?: boolean;
}) => {
    const primaryText = getPrimaryText(isGroup ? conv.name : getConversationWith(conv).getName());
    const secondaryText = getLastMessageText(conversation);
    const avatar = isGroup ? getIcon(conv) : getUserAvatar(conv);
    const unreadBadge = unreadMessages ? <div className="badge-unread">{unreadMessages}</div> : null;
    const blocked = !isGroup && checkIfUserIsBlocked(blockedUsers, getConversationWith(conv).getUid());
    const rightButton = deleteMultipleConversationsMode || messageToForward ? (
        <CheckConversationButton
            conversation={conv}
            handleClick={() => handleClick(conv, isGroup)}
            conversationsToDelete={conversationsToDelete}
            conversationsToForwardTo={conversationsToForwardTo}
        />
    ) : (
        <RemoveConversationButton
            conversation={conv}
            setRemoveConversation={setRemoveConversation}
        />
    );

    return (
        <div
            onClick={() => handleClick(conv, isGroup)}
            className={"conversationlistitem"}
            style={conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? { backgroundColor: userSettings.themeColor, color: "white" } : {}}
        >
            <div className="conversationlistitem-avatar">
                <Avatar
                    image={avatar}
                    style={{ backgroundColor: userSettings.themeColor }}
                    status={!isGroup && getConversationWith(conv).getStatus()}
                />
                {unreadBadge}
                {blocked && <div className="conversationlistitem-blocked">Blocked</div>}
            </div>
            <div className="conversationlistitem-text">
                <h1 className="conversationlistitem-primary-text">{primaryText}</h1>
                <h2
                    className="conversationlistitem-secondary-text"
                    style={conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? { color: "white" } : {}}
                >
                    {secondaryText}
                </h2>
            </div>
            <div className={`conversationlistitem-right-button ${isSideNav || conversationsToDelete.includes(conv) || (messageToForward && conversationsToForwardTo.includes(conv)) ? "mobile" : ""}`}>
                {rightButton}
            </div>
        </div>
    );
};

export default ConversationListItem;
