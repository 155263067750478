import Profile from 'anchor-ui/profile';
import defaultAvatar from "./../../assets/default_avatar.jpg";
import Button from 'anchor-ui/button';
import { CometChat } from '@cometchat-pro/chat';
import { FriendRequest } from './../../utils/class/FriendRequest';
import { getGenderFromMetadata } from './../../utils/getGenderFromMetadata';
import { getActiveColor } from './../../utils/activeColor';
import { useEffect, useState } from 'react';
import { metadataInterface } from '../../interfaces/metadataInterface';
import { Functions, httpsCallable } from 'firebase/functions';

const UserProfileConvComponent = ({
  user,
  friends,
  handleFriendRequest,
  incomingFriendRequests,
  handleAcceptFriendRequest,
  outgoingFriendRequests,
  functions,
}: {
  user: CometChat.User | undefined,
  friends: CometChat.User[],
  handleFriendRequest: (user: CometChat.User) => void,
  incomingFriendRequests: FriendRequest[],
  handleAcceptFriendRequest: (request: FriendRequest) => void
  outgoingFriendRequests: CometChat.User[],
  functions: Functions,
}) => {

  const [userData, setUserData] = useState<metadataInterface | null>(null);

  const fetchUserData = async (user: CometChat.User | undefined) => {
    setUserData(null);
    if (!user) return;

    const fetchUserProfile = httpsCallable(functions, 'fetchUserProfile');
    const userProfileResult = await fetchUserProfile({ uid: user.getUid() });
    const userProfile = userProfileResult.data as unknown as metadataInterface;

    setUserData(userProfile);
  }

  useEffect(() => {
    fetchUserData(user);
  }, [user]);

  const isFriendOrRequestSent = () => {
    if (!user) return null;
    return friends.some(friend => friend.getUid() === user.getUid()) || outgoingFriendRequests.some(request => request.getUid() === user.getUid());
  }

  if (!user) {
    return null;  // or return a loading spinner, or some placeholder content
  }

  let avatar = defaultAvatar;
  if (user.getAvatar() !== undefined) {
    avatar = user.getAvatar();
  }

  const hasReceivedFriendRequest = (user: CometChat.User) => {
    return incomingFriendRequests.some(friendRequest => friendRequest.getUid() === user.getUid());
  };

  const handleAcceptBtnClick = (user: CometChat.User) => {
    const friendRequest = incomingFriendRequests.find(friendRequest => friendRequest.getUid() === user.getUid());
    if (!friendRequest) return;

    handleAcceptFriendRequest(friendRequest);
  }

  const getAgeFromBirthDate = (birthDate: string): number | string => {
    const birthDateObj = new Date(birthDate);
    const now = new Date();
  
    let age = now.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = now.getMonth() - birthDateObj.getMonth();
    const dayDiff = now.getDate() - birthDateObj.getDate();
  
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return isNaN(age) ? "?" : age;
  };

  return (
    <div className='conversation-profile'>
      <Profile
        coverImage={avatar}
        avatar={avatar}
        header={user.getName()}
        secondaryText={getGenderFromMetadata(user, true)}
        status={user.getStatus()}
        coverImageStyle={{ backgroundColor: getActiveColor() }}
        button={
          <>
            {
              !isFriendOrRequestSent() &&
              <>
                {hasReceivedFriendRequest(user) ?
                  <Button onClick={() => handleAcceptBtnClick(user)}>
                    Accepteer vriendschapsverzoek
                  </Button> :
                  <Button onClick={() => handleFriendRequest(user)}>
                    Stuur Een Vriendschapsverzoek
                  </Button>
                }
              </>
            }
            <h1 className='conv-with-heading'>Leeftijd</h1>
            {getAgeFromBirthDate(userData?.about?.birthDate ?? "")}
          </>

        }
      />
    </div>
  );
}

export default UserProfileConvComponent;