import { CometChat } from '@cometchat-pro/chat';
import Modal from 'anchor-ui/modal';
import Button from 'anchor-ui/button';
import Input from 'anchor-ui/input';
import { useState } from 'react';
import { Functions, httpsCallable } from 'firebase/functions';

const WarnUserModal = ({
  userToWarn,
  setUserToWarn,
  setAlert,
  functions,
}: {
  userToWarn: CometChat.User | null;
  setUserToWarn: (userToWarn: CometChat.User | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
}) => {
  const [warningReason, setWarnReason] = useState("");

  const handleClose = () => {
    setWarnReason("");
    setUserToWarn(null);
  };

  const handleWarn = async () => {
    if (!userToWarn || !warningReason) return;

    try {
      const sendWarning = httpsCallable(functions, 'sendWarning');
      await sendWarning({ uid: userToWarn.getUid(), warningMessage: warningReason });

      setWarnReason("");
      setAlert({ message: 'Waarschuwing verstuurd', type: 'success' });
      setUserToWarn(null);
    } catch (error) {
      console.error("Error sending warning:", error);
      setAlert({ message: 'Er is iets fout gegaan bij het waarschuwen van de gebruiker', type: 'error' });
    }
  };

  if (!userToWarn) return <></>;

  return (
    <Modal
      open={userToWarn !== null}
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>
          <Button flatButton onClick={handleWarn}>WAARSCHUW</Button>
        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      <Input
        name={'extraInfo'}
        label={`[${userToWarn.getName()}] Waarschuwing reden`}
        value={warningReason}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWarnReason(event.target.value)}
        maxLength={500}
        style={{ maxWidth: '100%' }}
      />
    </Modal>
  );
};

export default WarnUserModal;
