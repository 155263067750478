import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { CometChat } from '@cometchat-pro/chat';

import { ActiveChannelInterface } from "../../interfaces/activeChannelInterface";
import { NotificationInterface } from "../../interfaces/notificationInterface";

import { GroupConversation } from "../../utils/class/GroupConversation";
import { FriendRequest } from "../../utils/class/FriendRequest";
import { fetchFriendsList } from "../../utils/cometChatUtils";
import {
  fetchIncomingFriendRequests,
  fetchOutgoingFriendRequests
} from "../ConversationSDK/friendRequests";
import { setActiveColor } from "../../utils/activeColor";

import ThemeProvider from 'anchor-ui/theme-provider';
import Alert from "anchor-ui/alert";

import ActionPage from "../ActionPage";
import { Chat } from "../Chat";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fetchProfile, getProfileFromMetadata } from "../../utils/updateMetadata";
import Homepage from "../Homepage";
import MetadataPage from "../MetadataPage";
import TermsAndServicesPage from "../TermsAndServicesPage";
import ChatRulesPage from "../ChatRulesPage";
import LoginRegisterPage from "../LoginRegisterPage";
import BlogPage from "../BlogPage";
import BlogLayout from "../BlogPage/Blogs/BlogLayout";
import FlirtenPage from "../FlirtenPage";
import { getAnalytics } from "firebase/analytics";

import { Helmet } from 'react-helmet';
import { UserSettingsInterface } from "../../interfaces/userSettingsInterface";
import { ConversationInterface } from "../../interfaces/conversationInterface";
import { isGroupConversation } from "../../utils/isGroupConversationCheck";
import { checkIfUserHasPermissions } from "../../utils/checkIfUserIsAdmin";

export function App() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoggingIn = useRef(false);
  const [isLoggedInAndHasLoaded, setIsLoggedInAndHasLoaded] = useState(false); // Possibly redundant (meant to prevent leaving channels randomly)
  const [loggedInUser, setLoggedInUser] = useState<{ firebaseUser: User | null, cometChatUser: CometChat.User | null } | null>(null);

  const [initialConversation, setInitialConversation] = useState<CometChat.Conversation | null>(null);
  // const [conversations, setConversations] = useState<CometChat.Conversation[] | GroupConversation[]>([]);

  const [conversations, setConversations] = useState<ConversationInterface[]>([]);
  const [removeConversation, setRemoveConversation] = useState<CometChat.Conversation | null>(null);

  const [friends, setFriends] = useState<CometChat.User[]>([]);
  const [blockedUsers, setBlockedUsers] = useState<CometChat.User[]>([]);
  const [incomingFriendRequests, setIncomingFriendRequests] = useState<FriendRequest[]>([]);
  const [outgoingFriendRequests, setOutgoingFriendRequests] = useState<FriendRequest[]>([]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [notifications, setNotifications] = useState<NotificationInterface[] | null>(null);
  const [channels, setChannels] = useState<CometChat.Group[]>([]);
  const channelsMounted = useRef(false);
  const [channelsOnlineMemberCount, setChannelsOnlineMemberCount] = useState<[{ guid: string, count: number }]>([{ guid: "", count: 0 }]);

  const [activeChannels, setActiveChannels] = useState<ActiveChannelInterface[]>([]);
  const [unreadCount, setUnreadCount] = useState<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>({});
  const [unreadNumber, setUnreadNumber] = useState<number>(0); // used for the favicon

  const [currentChat, setCurrentChat] = useState<ActiveChannelInterface>({ id: '', name: '', icon: '', joinedAt: Date.now(), isGroup: false });
  const [chatWithUser, setChatWithUser] = useState<CometChat.User>();
  const [messages, setMessages] = useState<CometChat.BaseMessage[]>([]);
  const [showLoadMostRecentMsgBtn, setShowLoadMostRecentMsgBtn] = useState(false);

  const [alert, setAlert] = useState<{ message: string, type: string } | null>(null);

  // User-specific data
  const [userSettings, setUserSettings] = useState<UserSettingsInterface>({
    themeColor: '#177FBF',
    profileIsPrivate: false,
    messageFontSize: 'font-normal',
    generalFontSize: 'font-normal',
    mediaDisabled: false,
    myMsgAlignment: 'right',
    otherMsgAlignment: 'left'
  });

  // Groupchannel members
  const [members, setMembers] = useState<CometChat.GroupMember[]>([]);

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  // Initialize Firebase Authentication and get a reference to the service
  const auth = getAuth(app);

  const functions = getFunctions(app, 'europe-west4');

  const handleLogout = async () => {
    try {
      await Promise.all([
        await auth.signOut(),
        await CometChat.logout(),
      ]);

      setLoggedInUser(null);

      // Reset all user-specific state
      setActiveColor('#177FBF');
      setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      setActiveChannels([]);
      setInitialConversation(null);
      setConversations([]);

      setFriends([]);
      setBlockedUsers([]);
      setIncomingFriendRequests([]);
      setOutgoingFriendRequests([]);

      setActiveTabIndex(0);
      setNotifications(null);
      setUnreadCount({});

      setIsLoggedInAndHasLoaded(false);
    } catch (error) {
      console.error("Logout failed with exception:", error);
    }
    isLoggingIn.current = false;
  };

  const handleUserLogin = async (firebaseUser: User) => {
    if (isLoggingIn.current) return false;
    isLoggingIn.current = true;

    try {
      const getCometChatAuthToken = httpsCallable(functions, 'getCometChatAuthToken');
      const result = await getCometChatAuthToken();
      const token = (result.data as { authToken: string }).authToken;

      // Check if the CometChat user is logged in
      let cometChatUser = await CometChat.getLoggedinUser();

      if (!cometChatUser) {
        cometChatUser = await CometChat.login(token);
      }

      setLoggedInUser({ firebaseUser, cometChatUser });

      return cometChatUser;
    } catch (error: any) {
      if ((error as { code: string }).code === 'AUTH_ERR_AUTH_TOKEN_NOT_FOUND') {
        setAlert({ message: 'Gebruiker is verbannen.', type: 'error' });
      } else {
        const errMsg = error.message ?? "Er ging iets mis tijdens het inloggen";
        setAlert({ message: errMsg, type: 'error' });
      }

      handleLogout();
      return false;
    } finally {
      isLoggingIn.current = false;
    }
  };

  // Monitor the Firebase authentication state
  const monitorAuthState = () => {
    setIsLoading(true);

    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser && firebaseUser.emailVerified) {
        // Firebase user is logged in, check if CometChat user is also logged in
        await handleUserLogin(firebaseUser);
      } else {
        // Firebase user is not logged in, reset user state
        setLoggedInUser(null);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // Load Google AdSense ads on page load
    try {
      ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
    } catch (e) {
      console.error("Error loading ads:", e);
    }

    // Call monitorAuthState inside useEffect to run it once when the component mounts
    monitorAuthState();
  }, []);

  useEffect(() => {
    // Determine the unread message count for the favicon (max 10, if 10 the favicon will be displayed as '9+')
    const count = Math.min(Math.max(Object.values(unreadCount).reduce((acc, { nr }) => acc + nr, 0), 0), 10);
    setUnreadNumber(count);
  }, [unreadCount]);

  const setCustomTheme = (user: CometChat.User | undefined) => {
    if (!user) return;

    const metadata = getProfileFromMetadata(user);
    const themeColor = metadata.settings?.themeColor ?? '#177FBF';

    setActiveColor(themeColor);

    setUserSettings({
      ...userSettings,
      ...metadata.settings
    });
  };

  const loadConversations = async (user: CometChat.User | null | undefined) => {
    if (!user) {
      console.error('No logged-in user found');
      return;
    }
    // Get all conversations
    let fetchedUserConversations: any[] = [];
    let fetchedUserGroupConversations: any[] = [];

    const userConversationsRequest = new CometChat.ConversationsRequestBuilder()
      .setConversationType(CometChat.RECEIVER_TYPE.USER)
      .setLimit(50)
      .build();

    const userConversationsPromise = await userConversationsRequest.fetchNext().then(
      userConversationList => {
        fetchedUserConversations = userConversationList.filter(conversation => {
          const conversationWith = conversation.getConversationWith();
          if (conversationWith instanceof CometChat.User) {
            return conversationWith.getUid() !== 'admin_user';
          }
        });
      },
      error => {
        console.error('User conversations fetching failed with error:', error);
      }
    );

    // Request for group conversations with 'private' tag
    const groupConversationsRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true)
      .withTags(true)
      .setTags(['private'])
      .build();

    const groupConversationsPromise = groupConversationsRequest.fetchNext().then(
      groupConversationList => {
        fetchedUserGroupConversations = groupConversationList;
      },
      error => {
        console.error('Group conversations fetching failed with error:', error);
      }
    );

    Promise.all([userConversationsPromise, groupConversationsPromise]).then(async () => {
      // Sort conversations by last message timestamp
      const fetchLastMessage = async (conversation: any): Promise<any> => {
        if (conversation.isGroup || 'guid' in conversation) {
          const guid = conversation.getGuid();
          const messagesRequest = new CometChat.MessagesRequestBuilder()
            .setLimit(1)
            .setGUID(guid)
            .build();

          try {
            const messages = await messagesRequest.fetchPrevious();
            return messages[0];
          } catch (error) {
            console.error('Group messages fetching failed with error:', error);
            return null;
          }
        } else {
          return conversation.getLastMessage();
        }
      };

      // add boolean isGroup to fetchedUserConversations and fetchedUserGroupConversations
      fetchedUserConversations.forEach(conversation => {
        conversation.isGroup = false;
      });

      // Create a GroupConversation object for each group conversation by combining the group and last message
      const groupConversations = await Promise.all(
        fetchedUserGroupConversations.map(async conversation => {
          const lastMessage = await fetchLastMessage(conversation);
          const groupConversation = new GroupConversation(conversation, lastMessage);

          return groupConversation;
        })
      );

      const loadedConversations = [...fetchedUserConversations, ...groupConversations];

      const sortConversations = async (loadedConversations: any[]) => {
        const lastMessages = await Promise.all(
          loadedConversations.map(conversation => fetchLastMessage(conversation))
        );

        const sortedConversations = loadedConversations.sort((a, b) => {
          const lastMsgA = lastMessages[loadedConversations.indexOf(a)];
          const lastMsgB = lastMessages[loadedConversations.indexOf(b)];

          if (!lastMsgA || !lastMsgB) return 0;
          return lastMsgB.getSentAt() - lastMsgA.getSentAt();
        });


        const formattedConversations: ConversationInterface[] = sortedConversations.map((conversation, index) => {

          const isMyMsg = conversation.lastMessage.sender.uid === user.getUid();
          const messageIsImg = conversation.lastMessage.type === 'image';

          const lastMessageText = messageIsImg ? 'Afbeelding' : conversation.lastMessage.text ?? "Bericht";

          return {
            conversation,
            lastMsg: lastMessageText,
            isMyMsg
          };
        });

        if (initialConversation) setInitialConversation(null);

        return formattedConversations
      };

      const formattedConversations = await sortConversations(loadedConversations);

      setConversations(formattedConversations);
    });
  };

  const fetchBlockedUsers = async () => {
    try {
      const blockedUsersRequest = new CometChat.BlockedUsersRequestBuilder()
        .setLimit(100)
        .build();
      const blockedUsersList = await blockedUsersRequest.fetchNext();
      setBlockedUsers(blockedUsersList);
      return blockedUsersList;
    } catch (error) {
      console.error("Error fetching blocked users:", error);
      return null;
    }
  };

  const loadIncomingFriendRequests = async (user?: CometChat.User, usersBlocked?: CometChat.User[] | null) => {
    const existingUser = user ? user : loggedInUser?.cometChatUser;
    if (!existingUser) return;

    let requests: FriendRequest[] = await fetchIncomingFriendRequests(functions, existingUser);

    const blockedUids = usersBlocked?.map(user => user.getUid()) ?? blockedUsers.map(user => user.getUid());

    // Block incoming friend requests from blocked users
    const blockedList = blockedUids ?? [];
    if (blockedList.length > 0 && requests?.length > 0) {
      requests = requests.filter(request => !blockedList.includes(request.getUid()));
    }

    setIncomingFriendRequests(requests ?? []);

    const addUniqueFriendRequestsToNotifications = (prev: NotificationInterface[] | null, requests: FriendRequest[]): NotificationInterface[] => {
      if (!prev) {
        // If there are no previous notifications, return the new requests as notifications
        return requests.map(request => ({ type: 'friendRequest', payload: request }));
      }

      // Create new notifications from the requests
      const newNotifications = requests.map(request => ({ type: 'friendRequest', payload: request }));

      // Filter out duplicate notifications
      const uniqueNotifications = newNotifications.filter(notification => {
        if (notification.type !== 'friendRequest') return true;
        else {
          const incomingUid = (notification.payload as any).uid;
          // Check for duplicates based on uid
          return !prev.some(notif => (notif.payload as any).uid === incomingUid)
        }
      });

      // Combine previous notifications with unique new notifications
      return [...prev, ...uniqueNotifications];
    };

    // If the user is already on the friends tab (index = 2), don't update the notifications
    if (activeTabIndex === 2) return;
    setNotifications(prev => addUniqueFriendRequestsToNotifications(prev, requests));
  };

  const loadOutgoingFriendRequests = async (user?: CometChat.User) => {
    const existingUser = user ? user : loggedInUser?.cometChatUser;
    if (!existingUser) return;

    const requests = await fetchOutgoingFriendRequests(functions, existingUser);
    setOutgoingFriendRequests(requests);
  };

  const loadFriends = async () => {
    try {
      const friendsRequest = await fetchFriendsList();
      setFriends(friendsRequest);
    } catch (error) {
      console.error("Error fetching friends list:", error);
    }
  };

  const loadChannels = async () => {
    const fetchUnreadMessagesForGroup = async (guid: string) => {
      const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setUnread(true)
        .setLimit(20)
        .setGUID(guid)
        .build();

      try {
        const messages = await messagesRequest.fetchPrevious();
        return messages.length;
      } catch (error) {
        console.error('Message fetching failed with error:', error);
        return 0;
      }
    };

    const fetchLastMessage = async (conversation: any): Promise<any> => {
      if ('guid' in conversation) {
        const guid = conversation.guid;
        const messagesRequest = new CometChat.MessagesRequestBuilder()
          .setLimit(1)
          .setGUID(guid)
          .build();

        try {
          const messages = await messagesRequest.fetchPrevious();
          return messages[0];
        } catch (error) {
          console.error('Group messages fetching failed with error:', error);
          return null;
        }
      } else {
        return conversation.getLastMessage();
      }
    };

    const sortConversations = async (loadedConversations: any[]) => {
      const lastMessages = await Promise.all(
        loadedConversations.map(conversation => fetchLastMessage(conversation))
      );

      const sortedConversations = loadedConversations.sort((a, b) => {
        const lastMsgA = lastMessages[loadedConversations.indexOf(a)];
        const lastMsgB = lastMessages[loadedConversations.indexOf(b)];

        if (!lastMsgA || !lastMsgB) return 0;

        return lastMsgB.getSentAt() - lastMsgA.getSentAt();
      });

      const formattedConversations = sortedConversations.map((conversation, index) => {
        const checkConversation: ConversationInterface = {
          conversation,
          lastMsg: 'error',
          isMyMsg: false
        };
        const isGroup = isGroupConversation(checkConversation);

        const lastMessage = isGroup
          ? conversation.lastMessage.message ?? conversation.lastMessage.text
          : conversation.lastMessage.text ?? "Error";

        const isMyMsg = conversation.lastMessage.sender.uid === loggedInUser?.cometChatUser!.getUid();
        const messageIsImg = conversation.lastMessage.type === 'image';

        let lastMessageText = 'error';

        lastMessageText = messageIsImg ? 'Afbeelding' : lastMessage;

        return {
          conversation,
          lastMsg: lastMessageText,
          isMyMsg
        };
      });

      setConversations(formattedConversations);
    };

    // Get all public groups
    const groupsRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(50)
      .withTags(true)
      .setTags(['public'])
      .build();

    const profileData = await fetchProfile(functions, loggedInUser?.cometChatUser?.getUid() ?? '');
    const metadata = getProfileFromMetadata(loggedInUser?.cometChatUser!);

    if (!profileData) {
      setAlert({ message: 'Er ging iets mis, probeer de pagina te herladen.', type: 'error' });
      return;
    }
    const isFemale = metadata.about.gender === 'Vrouw';
    const isUnknownGender = metadata.about.gender === 'Anders';
    const age = new Date().getFullYear() - new Date(profileData.about.birthDate ?? 0).getFullYear();
    const isTooYoung = age <= 15;
    const isYoung = age <= 25;
    const isAdult = age >= 18;
    const isOld = age >= 50;
    const isAdmin = checkIfUserHasPermissions(loggedInUser?.cometChatUser!);

    if (isTooYoung) {
      setAlert({ message: 'Je bent te jong om de chat te gebruiken.', type: 'error' });
      handleLogout();
    }

    // get time in format 15:13 hh:mm
    const timenow = new Date().toLocaleTimeString('nl-NL', { hour: '2-digit', minute: '2-digit' });
    if (!channelsMounted.current) console.log("trigger", timenow);

    // Leave all public joined channels on login
    groupsRequest.fetchNext().then(
      groupList => {
        const userSpecificChannels = groupList.filter(group => {
          // Leave all public joined channels on login
          if (group.getHasJoined() && !channelsMounted.current) {
            CometChat.leaveGroup(group.getGuid());

            // Remove the group from activeChannels if it was previously joined
            setActiveChannels(prev => prev.filter(channel => channel.id !== group.getGuid()));
          }

          // Allow admin to see all channels
          if (isAdmin) return true;

          // Filter out channels based on user's metadata
          switch (group.getName()) {
            case "[18+] Ongewenst":
              return isAdult;
            case '50+':
              return isOld;
            case 'Jongeren Chat':
              return isYoung;
            case 'Lesbox':
              return isFemale || isUnknownGender;
            case 'Oldies':
              return isOld;
            case 'Senioren':
              return isOld;
            case 'X-Box':
              return isUnknownGender;
            default:
              return true;
          }
        });

        // Sort the channels alphabetically by name
        userSpecificChannels.sort((a, b) => a.getName().localeCompare(b.getName()));

        setChannels(userSpecificChannels);
        channelsMounted.current = true;
      },
      error => {
        console.error('Groups list fetching failed with error:', error);
      }
    );

    let fetchedUserConversations: any[] = [];
    let fetchedUserGroupConversations: any[] = [];

    // Get all user conversations
    const userConversationsRequest = new CometChat.ConversationsRequestBuilder()
      .setConversationType(CometChat.RECEIVER_TYPE.USER)
      .setLimit(50)
      .build();

    // Get all group conversations
    const userConversationsPromise = userConversationsRequest.fetchNext().then(
      userConversationList => {
        fetchedUserConversations = userConversationList.filter(conversation => {
          const conversationWith = conversation.getConversationWith();
          return conversationWith instanceof CometChat.User && conversationWith.getUid() !== "admin_user";
        });

        // Get unread messages count for each conversation
        const unreadConversationCount: { [key: string]: { nr: number; chat: ActiveChannelInterface } } = {};
        userConversationList.forEach(conversation => {
          const conversationWith = conversation.getConversationWith();
          if (conversationWith instanceof CometChat.Group) return;
          if (conversationWith instanceof CometChat.User && conversationWith.getUid() === "admin_user") return;
          if (conversation.getUnreadMessageCount() === 0) return;

          unreadConversationCount[conversation.getConversationId()] = {
            nr: conversation.getUnreadMessageCount(),
            chat: {
              id: conversation.getConversationId(),
              receiverId: conversationWith.getUid(),
              icon: conversationWith.getAvatar(),
              name: conversationWith.getName(),
              joinedAt: Date.now(),
              isGroup: false
            }
          };
        });

        setUnreadCount(prev => ({ ...prev, ...unreadConversationCount }));
      },
      error => {
        console.error('User conversations fetching failed with error:', error);
      }
    );

    // Request for group conversations with 'private' tag
    const groupConversationsRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(30)
      .joinedOnly(true)
      .withTags(true)
      .setTags(['private'])
      .build();

    // Get unread messages count for each group conversation
    const groupConversationsPromise = groupConversationsRequest.fetchNext().then(
      async groupConversationList => {
        fetchedUserGroupConversations = await Promise.all(
          groupConversationList.map(async conversation => {
            const unreadCount = await fetchUnreadMessagesForGroup(conversation.getGuid());

            const lastMessage = await fetchLastMessage(conversation);
            const groupConversation = new GroupConversation(conversation, lastMessage);

            return { ...groupConversation, unreadCount };
          })
        );
      },
      error => {
        console.error('Group conversations fetching failed with error:', error);
      }
    );

    // Add boolean isGroup to fetchedUserConversations and fetchedUserGroupConversations 
    // to differentiate between user and group conversations
    Promise.all([userConversationsPromise, groupConversationsPromise]).then(() => {
      fetchedUserConversations.forEach(conversation => {
        conversation.isGroup = false;
      });

      fetchedUserGroupConversations.forEach(async conversation => {

        setUnreadCount(prev => ({
          ...prev,
          [conversation.guid]: {
            nr: conversation.unreadCount,
            chat: {
              id: conversation.guid,
              icon: conversation.icon,
              name: conversation.name,
              isGroup: true
            }
          }
        }));

        const group = new CometChat.Group(conversation);
        const lastMessage = await fetchLastMessage(conversation);

        return new GroupConversation(group, lastMessage);
      });

      // Combine user and group conversations, then sort them by last message timestamp
      const loadedConversations = [...fetchedUserConversations, ...fetchedUserGroupConversations];
      sortConversations(loadedConversations);
      loadFriends();
    });
  }

  const fetchChannelsOnlineMemberCount = async () => {
    const guids = channels.map(channel => channel.getGuid());

    try {
      const onlineMemberCount = await CometChat.getOnlineGroupMemberCount(guids);

      console.log('Online member count:', onlineMemberCount);

    } catch (error) {
      console.error('Error fetching online member count:', error);
    }
  }

  const loopFetchOnlineMembers = () => {
    // Fetch first online member count, then fetch every 5 minutes
    fetchChannelsOnlineMemberCount();

    // Fetch online member count every 5 minutes
    setInterval(() => {
      fetchChannelsOnlineMemberCount();
    }, 300000);
  }

  const updateFriendList = (user: CometChat.User) => {
    if (friends.some(friend => friend.getUid() === user.getUid())) {
      // Replace the user in the friends list with the updated user
      const updatedFriends = friends.map(friend => {
        if (friend.getUid() === user.getUid()) {
          return user;
        } else {
          return friend;
        }
      });

      const onlineFriends = updatedFriends.filter(friend => friend.getStatus() === 'online');
      const offlineFriends = updatedFriends.filter(friend => friend.getStatus() === 'offline');

      // Sort the onlineUsers and offlineUsers by name
      onlineFriends.sort((a, b) => a.getName().localeCompare(b.getName()));
      offlineFriends.sort((a, b) => a.getName().localeCompare(b.getName()));

      const sortedFriends = [...onlineFriends, ...offlineFriends];
      setFriends(sortedFriends);
    }
  };

  const createGroupMembersFromResponseData = (dataArray: any) => {
    const createdMembers: CometChat.GroupMember[] = dataArray.map((member: any) => {
      const groupMember = new CometChat.GroupMember(member.uid, member.scope);
      groupMember.setAvatar(member.avatar);
      groupMember.setName(member.name);
      groupMember.setStatus(member.status === "offline" ? "offline" : "online");
      groupMember.setMetadata(member.metadata);
      groupMember.setRole(member.role);
      groupMember.setMetadata(member.metadata);
      return groupMember;
    });

    return createdMembers;
  };

  const fetchGroupMembers = useCallback(async (currentChat: ActiveChannelInterface) => {
    try {
      // Fetch the group members from the firebase function
      const fetchGroupMembers = httpsCallable(functions, "fetchGroupMembers");
      const response = await fetchGroupMembers({ guid: currentChat.id });

      const groupMembers = createGroupMembersFromResponseData(response.data);

      let fetchedMembers = groupMembers;
      if (currentChat.isOpen) {
        fetchedMembers = groupMembers.filter((member: CometChat.GroupMember) => member.getStatus() !== "offline");
      } else {
        // Order the fetchedMembers so that online members are at the top
        fetchedMembers = groupMembers.sort((a: CometChat.GroupMember, b: CometChat.GroupMember) => {
          if (a.getStatus() !== "offline") return -1;
          if (b.getStatus() !== "offline") return 1;
          return 0;
        });
      }

      if (loggedInUser?.cometChatUser) {
        const user = new CometChat.GroupMember(loggedInUser.cometChatUser.getUid(), CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT);
        user.setAvatar(loggedInUser.cometChatUser.getAvatar());
        user.setName(loggedInUser.cometChatUser.getName());
        user.setStatus(loggedInUser.cometChatUser.getStatus());
        user.setMetadata(loggedInUser.cometChatUser.getMetadata());
        user.setRole(loggedInUser.cometChatUser.getRole());
        const filteredMembers = fetchedMembers.filter((member: CometChat.GroupMember) => member.getUid() !== user.getUid());
        filteredMembers.unshift(user);

        setMembers(filteredMembers);
      }
    } catch (error) {
      console.error('Error fetching group members:', error);
    }
  }, [loggedInUser, setMembers]);

  const updateGroupChannelMembers = (user: CometChat.User, members: CometChat.GroupMember[]) => {
    if (!loggedInUser || !loggedInUser.cometChatUser) return;
    if (!currentChat.isOpen) {

      const updatedMembers = members.map(member => {
        if (member.getUid() === user.getUid()) {
          const updatedMember = new CometChat.GroupMember(user.getUid(), member.getScope());
          updatedMember.setAvatar(user.getAvatar());
          updatedMember.setName(user.getName());
          updatedMember.setStatus(user.getStatus());
          updatedMember.setJoinedAt(member.getJoinedAt());
          updatedMember.setGuid(member.getGuid());
          updatedMember.setMetadata(member.getMetadata());
          return updatedMember;
        }
        return member;
      });

      // Sort members alphabetically and separate online/offline members
      const sortedMembers = updatedMembers.sort((a, b) => a.getName().localeCompare(b.getName()));

      const onlineMembers = sortedMembers.filter(member => member.getStatus() === 'online');
      const offlineMembers = sortedMembers.filter(member => member.getStatus() === 'offline');

      const updatedMembersList = [...onlineMembers, ...offlineMembers];

      // Move the logged-in user to the top of the list
      const loggedInMemberIndex = updatedMembersList.findIndex(member => member.getUid() === loggedInUser.cometChatUser!.getUid());

      if (loggedInMemberIndex !== -1) {
        const loggedInMember = updatedMembersList[loggedInMemberIndex];
        updatedMembersList.splice(loggedInMemberIndex, 1);
        updatedMembersList.unshift(loggedInMember);
      }

      // Update the members list
      setMembers(updatedMembersList);
      return;
    }

    // Remove the user from the members list if they are offline
    if (user.getStatus() === 'offline') {
      setMembers(prevMembers => prevMembers.filter(member => member.getUid() !== user.getUid()));
    }
  };

  const updateConversationUsers = (user: CometChat.User, conversations: ConversationInterface[]) => {
    // Update the user in the conversations list
    const updatedConversations = conversations.map(conversation => {
      // Check if the conversation is a group conversation
      if (isGroupConversation(conversation)) return conversation;

      const oldConversation = conversation.conversation as CometChat.Conversation;
      const conversationWith = (oldConversation as CometChat.Conversation).getConversationWith() as CometChat.User;

      if (conversationWith.getUid() === user.getUid()) {

        const updatedConversation = new CometChat.Conversation(
          oldConversation.getConversationId(),
          oldConversation.getConversationType(),
          oldConversation.getLastMessage(),
          user,
          oldConversation.getUnreadMessageCount(),
          oldConversation.getTags(),
        );

        return {
          ...conversation,
          conversation: updatedConversation
        }
      }

      return conversation;
    });

    // console.log("C - ", updatedConversations.length);
    setConversations(updatedConversations as ConversationInterface[]);
  };

  const updateChatWithUser = (user: CometChat.User) => {
    if (!chatWithUser) return;

    if (chatWithUser.getUid() === user.getUid()) {
      setChatWithUser(user);
    }
  }

  const updateCurrentChat = (user: CometChat.User) => {
    if (!currentChat.userProfile) return;

    if (currentChat.userProfile.getUid() === user.getUid()) {
      setCurrentChat(prevChat => {
        return {
          ...prevChat,
          userProfile: user
        };
      });
    }
  }

  const updateUser = (user: CometChat.User, conversations: ConversationInterface[], members: CometChat.GroupMember[]) => {
    updateFriendList(user);
    updateGroupChannelMembers(user, members);
    updateConversationUsers(user, conversations);
    updateChatWithUser(user);
    updateCurrentChat(user);
  }

  const listenToOnlineUsers = (conversations: ConversationInterface[], members: CometChat.GroupMember[]) => {
    let listenerID = "FRIEND_PRESENCE_LISTENER";

    // Remove the previous listener
    CometChat.removeUserListener(listenerID);
    CometChat.addUserListener(
      listenerID,
      new CometChat.UserListener({
        onUserOnline: (user: CometChat.User) => {
          updateUser(user, conversations, members);
        },
        onUserOffline: (user: CometChat.User) => {
          updateUser(user, conversations, members);
        }
      })
    );
  }

  const handleChangeChannel = async (channel: ActiveChannelInterface) => {
    // Check if the user has already joined the group
    const isJoined = activeChannels.some(c => c.id === channel.id);

    if (!isJoined) {
      const groupType = CometChat.GROUP_TYPE.PUBLIC as CometChat.GroupType;

      try {
        await CometChat.joinGroup(channel.id, groupType, '');
      } catch (error) {
        console.error('Group joining failed with exception:', error);
      }
    }

    // Open the channel and update the state
    channel.isOpen = true;
    channel.joinedAt = channel.joinedAt || Date.now();
    setActiveTabIndex(0); // 'Kanalen' tab
    setActiveChannels(prev => {
      // Create a Set to track unique channel IDs
      const uniqueChannelIds = new Set(prev.map(c => c.id));

      // Add the new channel if it's not already in the Set
      if (!uniqueChannelIds.has(channel.id)) {
        uniqueChannelIds.add(channel.id);
        prev.push(channel);
      }

      // Sort the channels alphabetically by name
      const newChannels = [...prev].sort((a, b) => a.name.localeCompare(b.name));

      return newChannels;
    });
    setCurrentChat({ ...channel, userProfile: undefined });
    setUnreadCount(prev => ({ ...prev, [channel.id]: { nr: 0, chat: channel } }));
  };

  const handleChangeGroupConversation = async (group: CometChat.Group) => {
    const guid = group.getGuid?.() ?? (group as any).guid;
    const name = group.getName?.() ?? (group as any).name;
    const isOwner = (group as any).owner === loggedInUser?.cometChatUser?.getUid();

    // Check if the groupchannel is in active channels
    const activeChannel = activeChannels.find(channel => channel.id === guid);
    if (activeChannel) {
      handleChangeChannel(activeChannel);
      return;
    }

    const newConversation = {
      id: guid,
      receiverId: guid,
      icon: '',
      name: name,
      joinedAt: Date.now(),
      isGroup: true,
      isOwner: isOwner,
      conversation: group
    };

    setCurrentChat(newConversation);

    // Fetch the conversation for the group
    const conversation = await CometChat.getConversation(guid, CometChat.RECEIVER_TYPE.GROUP);

    if (conversation) {
      CometChat.markAsRead(
        conversation.getLastMessage()
      ).then(
        () => {
          conversation.setUnreadMessageCount(0);

          setUnreadCount(prev => ({
            ...prev, [guid]: {
              nr: 0,
              chat: newConversation
            }
          }));
        },
        (error: any) => {
          console.error('Messages marking as read failed with error:', error);
        }
      );
    }
  };

  useEffect(() => {
    if (!loggedInUser || !loggedInUser.firebaseUser || !loggedInUser.cometChatUser || isLoggedInAndHasLoaded) return;

    // Load user-specific data when the user logs in
    const loadData = async () => {
      setCustomTheme(loggedInUser.cometChatUser ?? undefined);
      loadConversations(loggedInUser.cometChatUser);
      const usersBlocked = await fetchBlockedUsers();
      loadChannels();
      loadIncomingFriendRequests(loggedInUser.cometChatUser ?? undefined, usersBlocked);
      loadOutgoingFriendRequests(loggedInUser.cometChatUser ?? undefined);

      setIsLoggedInAndHasLoaded(true);
    }

    loadData();
  }, [loggedInUser, isLoggedInAndHasLoaded]);

  // useEffect(() => {
  //   if (channels.length > 0) {
  //     loopFetchOnlineMembers();
  //   }
  // }, [channels]);

  useEffect(() => {
    listenToOnlineUsers(conversations, members);
  }, [conversations, members]);

  useEffect(() => {
    // If there is no alert, return
    if (!alert) return;

    let duration = 4000;
    if (alert.type === 'error') duration = 8000;

    const timer = setTimeout(() => {
      setAlert(null);
    }, duration);

    return () => clearTimeout(timer);
  }, [alert]);

  useEffect(() => {
    // If loading is true for longer than 10 seconds, reload the page
    const timer = setTimeout(() => {
      if (isLoading) {
        window.location.reload();
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  function getHomePage() {
    return (
      <Homepage
        auth={auth}
        functions={functions}
        loggedInUser={loggedInUser}
        monitorAuthState={monitorAuthState}
        isLoading={isLoading}
        isLoggingIn={isLoggingIn}
        handleUserLogin={handleUserLogin}
        setAlert={setAlert}
      />
    );
  }

  function getChatPage() {
    return (
      <>
        <Chat
          loggedInUser={loggedInUser}
          isLoading={isLoading}

          unreadCount={unreadCount}
          setUnreadCount={setUnreadCount}
          conversations={conversations}
          setConversations={setConversations}
          initialConversation={initialConversation}
          setInitialConversation={setInitialConversation}
          loadConversations={loadConversations}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          notifications={notifications}
          setNotifications={setNotifications}
          channels={channels}
          loadIncomingFriendRequests={loadIncomingFriendRequests}
          incomingFriendRequests={incomingFriendRequests}
          loadOutgoingFriendRequests={loadOutgoingFriendRequests}
          outgoingFriendRequests={outgoingFriendRequests}
          blockedUsers={blockedUsers}
          fetchBlockedUsers={fetchBlockedUsers}
          friends={friends}
          loadFriends={loadFriends}
          setFriends={setFriends}
          handleLogout={handleLogout}

          currentChat={currentChat}
          setCurrentChat={setCurrentChat}
          chatWithUser={chatWithUser}
          setChatWithUser={setChatWithUser}
          messages={messages}
          setMessages={setMessages}
          members={members}
          setMembers={setMembers}

          setAlert={setAlert}
          userSettings={userSettings}
          setUserSettings={setUserSettings}

          showLoadMostRecentMsgBtn={showLoadMostRecentMsgBtn}
          setShowLoadMostRecentMsgBtn={setShowLoadMostRecentMsgBtn}

          functions={functions}

          handleChangeGroupConversation={handleChangeGroupConversation}
          activeChannels={activeChannels}
          setActiveChannels={setActiveChannels}

          handleChangeChannel={handleChangeChannel}
          fetchGroupMembers={fetchGroupMembers}

          removeConversation={removeConversation}
          setRemoveConversation={setRemoveConversation}
        />
      </>
    );
  }

  function getMetadataPage() {
    return (
      <MetadataPage
        setAlert={setAlert}
        loggedInUser={loggedInUser?.cometChatUser ?? {} as CometChat.User}
        handleLogout={handleLogout}
        loadChannels={loadChannels}
        functions={functions}
      />
    )
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
        backgroundColor: "white",
      }}
    >
      <Helmet>
        <link rel="icon" href={`favicon-${unreadNumber}.ico?v=${unreadNumber}`} />
      </Helmet>

      <ThemeProvider color={userSettings.themeColor}>
        {alert && <Alert className={'alert'} text={alert.message} type={alert.type} hideAlert={() => setAlert(null)} />}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={getHomePage()} />

            <Route path="blog" element={<BlogPage />} />
            <Route path="blog/:url" element={<BlogLayout />} />

            <Route path="chat" element={getChatPage()} />
            <Route path="chat-rules" element={<ChatRulesPage />} />
            <Route path="flirten" element={<FlirtenPage />} />
            <Route path="login" element={
              <LoginRegisterPage
                auth={auth}
                functions={functions}
                loggedInUser={loggedInUser}
                monitorAuthState={monitorAuthState}
                isLoading={isLoading}
                isLoggingIn={isLoggingIn}
                handleUserLogin={handleUserLogin}
                setAlert={setAlert}
              />
            } />
            <Route path="register-step-2" element={getMetadataPage()} />
            <Route path="terms" element={<TermsAndServicesPage />} />

            <Route path="action/:oobCode" element={<ActionPage />} />
            <Route path="*" element={<Navigate to="/chat" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>

    </div>
  );
}

export default App;
