// import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";
// import "@cometchat/uikit-elements";
import App from "./components/App";
import { CometChat } from "@cometchat-pro/chat";
import { CometChatConstants } from "./constants";

(async () => {
  try {
    // Initialize CometChat
    await CometChat.init(
      CometChatConstants.appId,
      new CometChat.AppSettingsBuilder()
        .subscribePresenceForAllUsers()
        .setRegion(process.env.REACT_APP_COMETCHAT_REGION)
        .build());

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
        // {/* <React.StrictMode> */}
        <App />
        // {/* </React.StrictMode> */}
    );
  } catch (error) {
    console.error("Initialization failed with error:", error);
    console.error("Please check your environment variables and ensure that you have a valid CometChat license key.");
    // Optionally, you can render an error message to the user
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
    root.render(
      <div>
        <h1>Initialization Error</h1>
        <p>There was an error initializing the application. Please try again later.</p>
      </div>
    );
  }
})();