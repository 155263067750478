import { useState, useEffect, SetStateAction } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  User,
  Auth,
  sendEmailVerification,
  updateProfile
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { CometChat } from '@cometchat-pro/chat';

import Input from 'anchor-ui/input';
import Card from 'anchor-ui/card';
import Button from 'anchor-ui/button';

import { validatePassword } from '../../utils/validatePassword';

import './styles.css';
import PasswordResetDialog from "./PasswordResetDialog";
import { Functions, httpsCallable } from "firebase/functions";
import { getProfileFromMetadata } from "../../utils/updateMetadata";

const FirebaseLogin = ({
  auth,
  functions,
  loggedInUser,
  monitorAuthState,
  isLoading,
  isLoggingIn,
  handleUserLogin,

  setAlert,
  children,
}: {
  auth: Auth;
  functions: Functions;
  loggedInUser: { firebaseUser: User | null, cometChatUser: CometChat.User | null } | null,
  monitorAuthState: () => void;
  isLoading: boolean;
  isLoggingIn: React.MutableRefObject<boolean>;
  handleUserLogin: (user: User, authToken?: String) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
  children?: React.ReactNode;
}) => {

  // Signup-related state
  const [signupEmail, setSignupEmail] = useState<string>("");
  const [signupPassword, setSignupPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  // Login-related state
  const [loginEmail, setLoginEmail] = useState<string>("");
  const [loginPassword, setLoginPassword] = useState<string>("");
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [unverifiedUser, setUnverifiedUser] = useState<User | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  // Username-related state
  const [username, setUsername] = useState<string>("");
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
  const [usernameError, setUsernameError] = useState<string>("");

  // Error-related state
  const [signupError, setSignupError] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");

  const [disableLogin, setDisableLogin] = useState<boolean>(false);

  // Timer-related state
  const [timer, setTimer] = useState<number>(0);

  // Miscellaneous state
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    monitorAuthState();
  }, []);

  useEffect(() => {
    // Redirect to chat page if user is logged in
    if (loggedInUser !== null && loggedInUser?.firebaseUser !== null && loggedInUser?.cometChatUser !== null) {
      const metadata = getProfileFromMetadata(loggedInUser.cometChatUser);

      if (metadata.about.birthDate === 'unknown') navigate('/register-step-2');
      else navigate("/chat");
    }
  }, [loggedInUser, navigate]);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (username) {
      // Check if username is valid
      if (verifyUsername(username)) {
        setUsernameError("");
      } else return;

      const timeout = setTimeout(async () => {
        const exists = await checkUsernameExists(username);
        setIsUsernameAvailable(!exists);
      }, 500); // 500ms debounce time

      setDebounceTimeout(timeout);
    } else {
      setIsUsernameAvailable(null);
    }
  }, [username]);

  useEffect(() => {
    // Clear unverified user when user logs in
    setUnverifiedUser(null);
  }, [isLogin]);

  useEffect(() => {
    // Timer for (re)sending verification email
    let interval: NodeJS.Timeout | null = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer]);

  const verifyUsername = (username: string) => {
    // Username cannot start or end with a space
    if (username.trim() !== username) {
      setUsernameError("Gebruikersnaam mag niet met een spatie beginnen of eindigen.");
      setIsUsernameAvailable(false);
      return false;
    }

    // Username cannot contain special characters
    if (username.match(/[^a-zA-Z0-9]/)) {
      setUsernameError("Gebruikersnaam mag alleen letters en cijfers bevatten.");
      setIsUsernameAvailable(false);
      return false;
    }
    
    setUsernameError("");
    return true;
  }

  const checkUsernameExists = async (username: string) => {
    // return false; // Disable username check for now

    const checkNameExists = httpsCallable(functions, 'checkNameExists');

    try {
      const result = await checkNameExists({ name: username });
      const exists = (result.data as { exists: boolean }).exists;
      return exists;
    } catch (error) {
      console.error("Error checking username existence:", error);
      return false;
    }
  };

  const handleSendVerifyMail = async (user: User) => {
    try {
      await sendEmailVerification(user, {
        url: window.location.href,
      });
      setTimer(60); // Set timer for 60 seconds
      setAlert({ message: "Verificatiemail is verstuurd. Controleer uw inbox.", type: "success" });
    } catch (e) {
      console.error('Error sending verification email:', e);
      setAlert({ message: "Fout bij het versturen van de verificatiemail. Probeer later nog eens.", type: "error" });
    }
  }

  const handleSignup = async (e: React.FormEvent) => {
    e.preventDefault();
    setSignupError("");

    if (!verifyUsername(username)) return;

    if (signupPassword !== confirmPassword) {
      setPasswordError("Wachtwoorden komen niet overeen.");
      return;
    }

    const passwordValidationError = await validatePassword(auth, signupPassword);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    setPasswordError("");

    if (isLoggingIn.current) return;
    isLoggingIn.current = true;
    try {

      // Firebase user creation
      const registeredUser = await createUserWithEmailAndPassword(auth, signupEmail, signupPassword)
      const user = registeredUser.user;

      // Update display name
      await updateProfile(user, {
        displayName: username
      });

      setUnverifiedUser(user);
      await handleSendVerifyMail(user);

      // Reset form fields
      setUsername("");
      setSignupEmail("");
      setSignupPassword("");
      setConfirmPassword("");

      setSignupError("");
      setPasswordError("");
      setLoginError("");
    } catch (error) {
      console.error('Error creating user:', error);
      setSignupError((error as Error).message);
    }
    isLoggingIn.current = false;
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoginError("");
    try {
      setDisableLogin(true);
      const userCredential = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);

      // Check if the user is verified
      if (userCredential.user.emailVerified) {
        handleUserLogin(userCredential.user);
      } else {
        setUnverifiedUser(userCredential.user);
        auth.signOut();
        setLoginError("Uw account is nog niet geverifieerd. Controleer uw inbox voor de verificatiemail.");
      }
    } catch (error) {
      if ((error as any).code === 'auth/invalid-credential') {
        setLoginError("Verkeerd emailadres of wachtwoord");
      } else {
        setLoginError((error as Error).message);
      }
    } finally {
      setDisableLogin(false);
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    setDisableLogin(isLoggingIn.current);
  }, [isLoggingIn.current]);

  return (
    <section>
      <PasswordResetDialog
        isOpen={dialogOpen}
        setIsOpen={setDialogOpen}
        auth={auth}
        email={loginEmail}
      />

      <div className={`auth-container ${children ? 'auth-children' : ''}`}>
        <Card className={`auth-card ${isLogin ? 'login' : 'register'}`}>
          {isLoading ? (
            <p>U wordt doorgestuurd...</p>
          ) : (
            <div>
              <section className="auth-selection">
                <p
                  className={isLogin ? 'active' : ''}
                  onClick={() => setIsLogin(true)}
                >
                  INLOGGEN
                </p>
                <p
                  className={!isLogin ? 'active' : ''}
                  onClick={() => setIsLogin(false)}
                >
                  REGISTREREN
                </p>
              </section>

              {isLogin ? (
                <form onSubmit={handleLogin} className="auth-form">
                  <Input
                    label="E-mail adres"
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="login-email"
                    value={loginEmail}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLoginEmail(e.target.value)}
                    className="auth-input"
                    required
                    maxLength={50}
                  />
                  <Input
                    label="Wachtwoord"
                    type="password"
                    placeholder="Wachtwoord"
                    name="password"
                    id="login-password"
                    value={loginPassword}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLoginPassword(e.target.value)}
                    className="auth-input"
                    required
                    maxLength={256}
                  />
                  {loginError && <p className="auth-error-message">{loginError}</p>}
                  {unverifiedUser && (
                    <>
                      <Button
                        inverted
                        onClick={() => handleSendVerifyMail(unverifiedUser)}
                        disabled={timer > 0}
                      >
                        {timer === 0 ? 'Verificatie-e-mail opnieuw verzenden' :
                          `U kunt een nieuwe verificatiemail verzenden over ${formatTime(timer)}`
                        }
                      </Button>
                    </>
                  )}
                  <Button
                    type="submit"
                    className="auth-submit-button"
                    disabled={disableLogin}
                  >
                    INLOGGEN{disableLogin && '...'}
                  </Button>
                  <span className="forgot-pw" onClick={() => setDialogOpen(true)}>Wachtwoord vergeten?</span>
                </form>
              ) : (
                <form onSubmit={handleSignup} className="auth-form">
                  {unverifiedUser ? (
                    <div className="email-sent">
                      <p className="auth-success-message">Verificatie-email verzonden. Controleer uw inbox.</p>
                      <Button
                        inverted
                        onClick={() => handleSendVerifyMail(unverifiedUser)}
                        disabled={timer > 0}
                      >
                        {timer === 0 ? 'Verificatie-e-mail opnieuw verzenden' :
                          `U kunt een nieuwe verificatiemail verzenden over ${formatTime(timer)}`
                        }
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div className="username-input-group">

                        {usernameError ? <small className="username-unavailable">{usernameError}</small> : (
                          isUsernameAvailable === false ? <small className="username-unavailable">Gebruikersnaam is niet beschikbaar</small> :
                            isUsernameAvailable === true && <small className="username-available">Gebruikersnaam is beschikbaar</small>
                        )}

                        <Input
                          label="Gebruikersnaam"
                          type="text"
                          placeholder="Gebruikersnaam"
                          name="username"
                          value={username}
                          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setUsername(e.target.value)}
                          className="auth-input"
                          required
                          maxLength={50}
                        />
                      </div>
                      <Input
                        label="E-mail adres"
                        type="email"
                        placeholder="Email"
                        name="email"
                        id="signup-email"
                        value={signupEmail}
                        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setSignupEmail(e.target.value)}
                        className="auth-input"
                        required
                        maxLength={50}
                      />
                      <Input
                        label="Wachtwoord"
                        type="password"
                        placeholder="Wachtwoord"
                        name="password"
                        id="signup-password"
                        value={signupPassword}
                        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setSignupPassword(e.target.value)}
                        className="auth-input"
                        error={passwordError}
                        maxLength={256}
                      />
                      <Input
                        label="Bevestig wachtwoord"
                        type="password"
                        placeholder="Bevestig wachtwoord"
                        id="signup-confirm-password"
                        value={confirmPassword}
                        onChange={(e: { target: { value: SetStateAction<string>; }; }) => setConfirmPassword(e.target.value)}
                        className="auth-input"
                        required
                        maxLength={256}
                      />

                      {signupError && <p className="auth-error-message">{signupError}</p>}
                      <Button
                        type="submit"
                        className="auth-submit-button"
                        color={'#FF9700'}
                        disabled={isUsernameAvailable === false}
                      >
                        REGISTREER ACCOUNT
                      </Button>
                    </>
                  )}
                </form>
              )}
            </div>
          )}
        </Card>

        {children && <div className="auth-info">{children}</div>}
      </div>
    </section>
  );
}

export default FirebaseLogin;