import { useEffect, useState } from 'react';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import { IconClose } from 'anchor-ui/icons';
import defaultImage from "./../../assets/default_channel_image.png";
import defaultSelectedImage from "./../../assets/default_channel_image_selected.png";
import { getActiveColor } from '../../utils/activeColor';
import Avatar from 'anchor-ui/avatar';
import { CometChat } from '@cometchat-pro/chat';

import LexaAvatarSvg from '../../assets/lexa-avatar.svg';
import LexaLogoTextSvg from '../../assets/lexa-logo-text.svg';
import PikantCamsSvg from '../../assets/pikantcams-avatar.svg';
import PikantCamsTextSvg from '../../assets/pikantcams-text.svg';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';

const ads = [
  {
    id: 1,
    primaryText: <img src={PikantCamsTextSvg} style={{ width: '80%' }} />,
    avatar: <Avatar image={PikantCamsSvg} />,
    url: 'https://www.pikantcams.nl/nl?pi=chatplaza_v2'
  },
  {
    id: 2,
    primaryText: <b>Singles in jouw regio</b>,
    avatar: <Avatar image={LexaAvatarSvg} />,
    url: 'https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws='
  },
  {
    id: 3,
    primaryText: <b>18+ Webcam Chatten</b>,
    avatar: <Avatar image={PikantCamsSvg} />,
    url: 'https://www.pikantcams.nl/nl?pi=chatplaza_v2'
  },
  {
    id: 4,
    primaryText: <img src={LexaLogoTextSvg} />,
    avatar: <Avatar image={LexaAvatarSvg} />,
    url: 'https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws='
  }
];

const ChannelListComponent = ({
  members,
  channels,
  activeChannels,
  unreadCount,
  handleChangeChannel,
  currentChat,
  handleLeaveChannel,
  generalFontSize,
}: {
  members: CometChat.GroupMember[];
  channels: CometChat.Group[];
  activeChannels: ActiveChannelInterface[];
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  currentChat: ActiveChannelInterface;
  handleLeaveChannel: (channel: ActiveChannelInterface) => void;
  generalFontSize: string;
}) => {
  const nonActiveChannels = channels.filter(channel => !activeChannels.some(activeChannel => activeChannel.id === channel.getGuid()));
  const activeChannelsWithoutCurrent = activeChannels.filter(channel => channel.id !== currentChat.id);
  const currentChatDetailed = channels.filter(channel => channel.getGuid() === currentChat.id)[0];
  const onlineMembers = members.filter(member => member.getStatus() === 'online');

  const [currentChatIsPublic, setCurrentChatIsPublic] = useState(false);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);
  const [moveIn, setMoveIn] = useState(true);

  const handleAdClick = (url: string) => {
    window.open(url, '_blank');
  };

  const LeaveChannelButton = ({ channel }: { channel: ActiveChannelInterface }) => {
    return (
      <button
        type="button"
        className='li-button text-white'
        onClick={(event) => {
          event.stopPropagation();
          handleLeaveChannel(channel);
        }}
      >
        <IconClose color={'white'} />
      </button>
    );
  };

  useEffect(() => {
    const isActiveAndPublic = activeChannels.some(channel => channel.id === currentChat.id);
    setCurrentChatIsPublic(isActiveAndPublic);
  }, [currentChat, activeChannels]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMoveIn(false); // Start move-out
      setTimeout(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length); // Change ad
        setMoveIn(true); // Start move-in
      }, 500); // Match move-out duration in CSS
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="active-channels">
        {currentChatIsPublic &&
          <List
            header="Huidig Kanaal"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            <ListItem
              primaryText={currentChat.name}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              secondaryText={`${onlineMembers.length ?? 1} online`}
              avatar={
                <Avatar
                  image={currentChatDetailed && currentChatDetailed.getIcon() ? currentChatDetailed.getIcon() : currentChat.icon ? currentChat.icon : defaultSelectedImage}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              rightButton={<LeaveChannelButton channel={currentChat} />}
              style={{ backgroundColor: getActiveColor() }}
              activeChannel={true}
            />
          </List>
        }

        {activeChannelsWithoutCurrent.length > 0 &&
          <List
            header="Actieve Kanalen"
            headerStyle={getFontSizeStyle(generalFontSize)}
          >
            {activeChannelsWithoutCurrent.map((channel, index) => {
              const channelUnreadCount = unreadCount[channel.id] ? unreadCount[channel.id].nr : 0;

              return (
                <ListItem
                  key={index}
                  secondaryText={`Actief kanaal`}
                  primaryText={channel.name}
                  primaryTextStyle={getFontSizeStyle(generalFontSize)}
                  badge={channelUnreadCount > 0 && <div className='badge-unread'>{channelUnreadCount}</div>}
                  avatar={
                    <Avatar
                      image={channel.icon ? channel.icon : defaultSelectedImage}
                      style={{ backgroundColor: getActiveColor() }}
                    />
                  }
                  onClick={() => handleChangeChannel(channel)}
                  className={`channel-item active`}
                  rightButton={<LeaveChannelButton channel={channel} />}
                  style={{ backgroundColor: getActiveColor() }}
                  activeChannel={true}
                />
              );
            })}
          </List>
        }
      </div>

      <List
        header="Kanalen"
        headerStyle={getFontSizeStyle(generalFontSize)}
      >
        {nonActiveChannels.map((channel, index) => (
          <div key={index}>
            <ListItem
              primaryText={channel.getName()}
              primaryTextStyle={getFontSizeStyle(generalFontSize)}
              secondaryText={`Publiek kanaal`}
              avatar={
                <Avatar
                  image={channel.getIcon() ? channel.getIcon() : defaultImage}
                  style={{ backgroundColor: getActiveColor() }}
                />
              }
              onClick={() => handleChangeChannel({ id: channel.getGuid(), name: channel.getName(), icon: channel.getIcon(), joinedAt: 0, isGroup: true })}
            />
            {index === 1 && (
              <div className={`ads-section ${moveIn ? 'slide-in' : 'slide-out'}`}>
                <ListItem
                  primaryText={ads[currentAdIndex].primaryText}
                  avatar={ads[currentAdIndex].avatar}
                  onClick={() => handleAdClick(ads[currentAdIndex].url)}
                />
              </div>
            )}
          </div>
        ))}
      </List>
    </div>
  );
};

export default ChannelListComponent;
