import { CometChat } from '@cometchat-pro/chat';
import List from 'anchor-ui/list';
import ListItem from 'anchor-ui/list-item';
import Avatar from 'anchor-ui/avatar';

import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import privateChannelImage from "./../../assets/private_channel.png";
import AddUserToGroupModal from './AddUserToGroupModal';
import { useEffect, useRef, useState } from 'react';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import { getProfileFromMetadata } from '../../utils/updateMetadata';
import { Functions } from 'firebase/functions';
import GroupMemberListItem from './GroupMemberListItem';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { checkIfUserHasPermissions, checkIfUserIsAdmin, checkIfUserIsModerator } from '../../utils/checkIfUserIsAdmin';
import {
  IconSettings,
  IconPeople,
  IconSticker,
} from 'anchor-ui/icons';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';

const GroupListComponent = ({
  members,
  setMembers,
  loggedInUser,
  friends,
  blockedUsers,
  handleBlockUser,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  handleBanUser,
  handleKickUser,
  handleLogoutKickUser,
  currentChat,
  setCurrentChat,
  startPrivateChat,
  setUserToReport,
  setUserToWarn,
  setAlert,
  functions,
  userSettings,
  refreshConversations,
}: {
  members: CometChat.GroupMember[];
  setMembers: (members: CometChat.GroupMember[]) => void;
  loggedInUser: CometChat.User | null;
  friends: CometChat.User[];
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  currentChat: ActiveChannelInterface;
  setCurrentChat: (chat: any) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setUserToWarn: (user: CometChat.User) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  functions: Functions;
  userSettings: UserSettingsInterface;
  refreshConversations: () => void;
}) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [admins, setAdmins] = useState<CometChat.User[]>([]);
  const [friendMembers, setFriendMembers] = useState<CometChat.User[]>([]);
  const [regularMembers, setRegularMembers] = useState<CometChat.User[]>(members);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const generalFontSize = userSettings.generalFontSize;

  const checkIfFriend = (user: CometChat.User) => {
    return friends.some(friend => friend.getUid() === user.getUid());
  }

  useEffect(() => {
    const membersAlphabetically = members.sort((a, b) => a.getName().localeCompare(b.getName()));
    const uniqueMembers = membersAlphabetically.filter((member, index, self) =>
      index === self.findIndex(m => m.getUid() === member.getUid())
    );

    const admins: CometChat.User[] = [];
    const friendMembers: CometChat.User[] = [];
    const regularMembers: CometChat.User[] = [];

    uniqueMembers.forEach(member => {
      if (checkIfUserHasPermissions(member)) {
        admins.push(member);
        return;
      }
      if (checkIfFriend(member)) {
        friendMembers.push(member);
        return;
      }
      regularMembers.push(member);
    });

    setAdmins(admins);
    setFriendMembers(friendMembers);
    setRegularMembers(regularMembers);
  }, [members]);

  const isFriend = (user: CometChat.User) => {
    return friends.some(friend => friend.getUid() === user.getUid());
  };

  const isPublicProfile = (user: CometChat.User) => {
    const profile = getProfileFromMetadata(user);
    return !profile.settings?.profileIsPrivate;
  }

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  }

  const checkBlocked = (user: CometChat.User) => {
    return checkIfUserIsBlocked(blockedUsers, user.getUid());
  }

  const handleChangeGroupIcon = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (event.target.files == null ||
        event.target.files.length === 0 ||
        currentChat.conversation === undefined
      ) {
        throw new Error('Invalid file or conversation');
      }

      const file = event.target.files[0];

      // Create a new MediaMessage with admin_user as receiver and image type
      const mediaMessage = new CometChat.MediaMessage(
        "admin_user",
        file,
        CometChat.MESSAGE_TYPE.IMAGE,
        CometChat.RECEIVER_TYPE.USER
      );

      const message = await CometChat.sendMediaMessage(mediaMessage);

      if (!(message instanceof CometChat.MediaMessage)) {
        throw new Error('Invalid message received');
      }

      const currentGroup = currentChat.conversation as any;

      // Update the group icon
      const updatedGroup = new CometChat.Group(currentGroup.guid,
        currentGroup.name,
        currentGroup.type,
        "",
        message.getURL()
      );

      // Save the updated group
      const updatedGroupResult = await CometChat.updateGroup(updatedGroup);

      setCurrentChat({ ...currentChat, conversation: updatedGroupResult });
      refreshConversations();
      setAlert({ message: 'Groepsafbeelding gewijzigd', type: 'success' });

    } catch (error) {
      console.error('Error updating group:', error);
      setAlert({ message: 'Er is iets mis gegaan', type: 'error' });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    handleChangeGroupIcon(event);

    // Reset the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleImageClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const groupMemberListItemProps = {
    loggedInUser,
    currentChat,
    startPrivateChat,
    showUserProfile,
    setUserToReport,
    setUserToWarn,
    handleAddOrRemoveFriend,
    handleBlockUser,
    handleKickUser,
    handleLogoutKickUser,
    handleBanUser,
    loggedInUserIsAdmin: checkIfUserIsAdmin(loggedInUser!),
    loggedInUserIsModerator: checkIfUserIsModerator(loggedInUser!),
    generalFontSize,
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />

      <AddUserToGroupModal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
        friends={friends}
        members={members}
        setMembers={setMembers}
        currentChat={currentChat}
        blockedUsers={blockedUsers}
        loggedInUser={loggedInUser}
        setAlert={setAlert}
        functions={functions}
      />

      {/* Admin */}
      {admins.length > 0 && (
        <List
          header={!currentChat.isOwner && 'Admins'}
          headerStyle={getFontSizeStyle(generalFontSize)}
        >
          {currentChat.isOwner &&
            <ListItem
              primaryText="Instellingen"
              avatar={
                <>
                  <IconMenu
                    icon={<IconSettings color={userSettings.themeColor} />}
                  >
                    <MenuItem
                      text="Voeg leden toe"
                      icon={<IconPeople />}
                      onClick={() => setModalIsOpen(true)}
                    />
                    <MenuItem
                      text="Verander groepsafbeelding"
                      icon={<IconSticker />}
                      onClick={() => handleImageClick()}
                    />
                  </IconMenu>
                </>
              }
            />
          }
          {admins.map((admin, index) => (
            <GroupMemberListItem
              key={index}
              {...groupMemberListItemProps}
              member={admin}
              isAdmin={checkIfUserIsAdmin(admin)}
              isModerator={true}
              isFriend={isFriend(admin)}
              isBlocked={checkBlocked(admin)}
              isPublicProfile={isPublicProfile(admin)}
              hasSentFriendRequest={hasSentFriendRequest(admin)}
              hasReceivedFriendRequest={hasReceivedFriendRequest(admin)}
            />
          ))}
        </List>
      )}

      {/* Friends */}
      {friendMembers.length > 0 && (
        <List
          header={(!currentChat.isOwner || admins.length > 0) && 'Vrienden'}
          headerStyle={getFontSizeStyle(generalFontSize)}
        >
          {(currentChat.isOwner && admins.length === 0) &&
            <ListItem
              primaryText='Voeg leden toe'
              avatar={
                <Avatar
                  image={privateChannelImage}
                  style={{ backgroundColor: userSettings.themeColor }}
                />
              }
              onClick={() => setModalIsOpen(true)}
            />
          }
          {friendMembers.map((friend, index) => (
            <GroupMemberListItem
              key={index}
              {...groupMemberListItemProps}
              member={friend}
              isAdmin={checkIfUserIsAdmin(friend)}
              isModerator={false}
              isFriend={true}
              isBlocked={checkBlocked(friend)}
              isPublicProfile={isPublicProfile(friend)}
              hasSentFriendRequest={false}
              hasReceivedFriendRequest={false}
            />
          ))}
        </List>
      )}

      {/* Regular members */}
      {regularMembers.length > 0 && (
        <List
          header={(!currentChat.isOwner || admins.length > 0) && 'Gebruikers'}
          headerStyle={getFontSizeStyle(generalFontSize)}
        >
          {currentChat.isOwner && admins.length === 0 &&
            <ListItem
              primaryText='Voeg leden toe'
              avatar={
                <Avatar
                  image={privateChannelImage}
                  style={{ backgroundColor: userSettings.themeColor }}
                />
              }
              onClick={() => setModalIsOpen(true)}
            />
          }
          {regularMembers.map((member, index) => (
            <GroupMemberListItem
              key={index}
              {...groupMemberListItemProps}
              member={member}
              isAdmin={checkIfUserIsAdmin(member)}
              isModerator={checkIfUserIsModerator(member)}
              isFriend={false}
              isBlocked={checkBlocked(member)}
              isPublicProfile={isPublicProfile(member)}
              hasSentFriendRequest={hasSentFriendRequest(member)}
              hasReceivedFriendRequest={hasReceivedFriendRequest(member)}
            />
          ))}
        </List>
      )}
    </>
  );
};

export default GroupListComponent;
