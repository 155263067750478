import FirebaseLogin from "../Login";
import {
  User,
  Auth,
} from "firebase/auth";
import { CometChat } from '@cometchat-pro/chat';
import Lottie from 'react-lottie';

import './styles.css';
import { Functions } from "firebase/functions";

import IconSmiley from '../../assets/hp-icon-smiley.svg';
import IconRules from '../../assets/hp-icon-rules.svg';
import IconProfile from "../../assets/hp-icon-profile.svg"

import OrangeV4 from "../../assets/orange-v4.svg";

import LottieAnimation1 from '../../assets/lotties/Chat_Bubble.json';
import LottieAnimation2 from '../../assets/lotties/Global_Discussion.json';
// import LottieAnimation3 from '../../assets/lotties/Group_Chat.json';
// import LottieAnimation4 from '../../assets/lotties/Rating.json';
// import LottieAnimation5 from '../../assets/lotties/Support.json';
// import LottieAnimation6 from '../../assets/lotties/Webcam.json';

import Layout from "../Layout";

import SinterklaasBG from '../../assets/sinterklaas.avif';
import ChristmasBG from '../../assets/christmas-bg.jpg';
import NewYearsBG from '../../assets/newyears.avif';
import ValentinesBG from '../../assets/valentines.avif';
import EasterBG from '../../assets/easter.avif';
import KingsdayBG from '../../assets/kingsday.jpg';
import HalloweenBG from '../../assets/halloween.avif';

const Homepage = ({
  auth,
  functions,
  loggedInUser,
  monitorAuthState,
  isLoading,
  isLoggingIn,
  handleUserLogin,

  setAlert,
}: {
  auth: Auth;
  functions: Functions;
  loggedInUser: { firebaseUser: User | null, cometChatUser: CometChat.User | null } | null,
  monitorAuthState: () => void;
  isLoading: boolean;
  isLoggingIn: React.MutableRefObject<boolean>;
  handleUserLogin: (user: User, authToken?: String) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string } | null>>;
}) => {

  /**
   * Holiday backgrounds
   */
  const currentDate = new Date();
  const month = currentDate.getMonth();
  const date = currentDate.getDate();
  const day = currentDate.getDay();

  const isNewYears = (month === 11 && date === 31) || (month === 0 && date === 1);
  const isSinterklaas = ((month === 10 && day >= 15) || (month === 11 && date <= 5));
  const isChristmasTime = month === 11 && !isSinterklaas && !isNewYears;
  const isValentinesDay = (month === 1 && date === 14);
  const isEaster = (month === 3 && date === 20) || (month === 3 && date === 21);
  const isKingsDay = (month === 3 && ((date === 27 && day !== 0) || (date === 26 && day === 0)));
  const isHalloween = (month === 9 && date === 31);

  const getHolidayBackground = () => {
    if (isSinterklaas) return SinterklaasBG;
    else if (isChristmasTime) return ChristmasBG;
    else if (isNewYears) return NewYearsBG;
    else if (isValentinesDay) return ValentinesBG;
    else if (isEaster) return EasterBG;
    else if (isKingsDay) return KingsdayBG;
    else if (isHalloween) return HalloweenBG;
    return null;
  };

  const holidayBackground = getHolidayBackground();

  /**
   * Lottie options
   */
  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const lottie1 = { ...defaultOptions, animationData: LottieAnimation1 };
  const lottie2 = { ...defaultOptions, animationData: LottieAnimation2 };
  // const lottie3 = { ...defaultOptions, animationData: LottieAnimation3 };
  // const lottie4 = { ...defaultOptions, animationData: LottieAnimation4 };
  // const lottie5 = { ...defaultOptions, animationData: LottieAnimation5 };
  // const lottie6 = { ...defaultOptions, animationData: LottieAnimation6 };

  return (
    <Layout>
      <section className="hero-big"
        style={
          holidayBackground
            ? {
              backgroundImage: `linear-gradient(90deg, rgba(45, 97, 154, 0.8) 0%, rgba(62, 188, 247, 0.5) 100%), url(${holidayBackground})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }
            : {}
        }
      >
        <section className="homepage-container">
          <div className='container-content'>
            <div className="hero-content">
              <div className="text-container">
                <h1 className="hero-text">Gezellig chatten, nieuwe mensen ontmoeten, altijd en overal!</h1>
                <p>
                  Ontmoet nieuwe mensen in onze chatboxen! Maak een profiel aan, start direct een chatgesprek en geniet urenlang van gezellig chatten bij ChatPlaza.
                </p>
              </div>

              <div className="orange-swirl">
                <div className="hero-orange-stick" />
                <img src={OrangeV4} alt="Orange" className="hero-orange-v4" />
              </div>


              <FirebaseLogin
                auth={auth}
                functions={functions}
                loggedInUser={loggedInUser}
                monitorAuthState={monitorAuthState}
                isLoading={isLoading}
                isLoggingIn={isLoggingIn}
                handleUserLogin={handleUserLogin}
                setAlert={setAlert}
              />
            </div>
          </div>
        </section>

        <div className="hero-divider">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
          </svg>
        </div>
      </section>

      <section className="page-container">
        <div className='container-content'>

          <div className="responsive-auth">
            <FirebaseLogin
              auth={auth}
              functions={functions}
              loggedInUser={loggedInUser}
              monitorAuthState={monitorAuthState}
              isLoading={isLoading}
              isLoggingIn={isLoggingIn}
              handleUserLogin={handleUserLogin}
              setAlert={setAlert}
            />
          </div>

          <div>
            <section className="features-section">
              <div>
                <div className="icon">
                  <img src={IconSmiley} alt="Smiley" />
                </div>
                <p className="icon-title">VOOR IEDEREEN</p>
                <p className="description">
                  Duik in onze chatboxen, start met chatten met vreemden en ontdek een bruisende online chatgemeenschap. Jongens, meiden, dames én heren zijn van harte welkom! Vertel over jezelf, maak contact en geniet van boeiende gesprekken in onze chatboxen.
                </p>
              </div>

              <div>
                <div className="icon">
                  <img src={IconRules} alt="Rules" />
                </div>
                <p className="icon-title">VEILIG CHATTEN</p>
                <p className="description">
                  Om je chatervaring veilig en plezierig te houden, zijn AI chatbots en ervaren moderators continu actief in onze chatboxen. Zij zorgen voor een prettige sfeer, bewaken de chatveiligheid en staan altijd klaar om jouw vragen te beantwoorden of eventuele problemen op te lossen. Zo kun je met een gerust hart chatten!
                </p>
              </div>

              <div>
                <div className="icon">
                  <img src={IconProfile} alt="Picture" />
                </div>
                <p className="icon-title">CHAT MET EEN PROFIEL</p>
                <p className="description">
                  Ontmoet leuke chatters in onze chatboxen en laat zien wie jij bent! Maak een persoonlijk profiel aan zodat je eenvoudig kunt laten weten wat je leuk vindt en met wie je wilt chatten. Chatten met een profiel zorgt voor meer diepgang, echte connecties en boeiende gesprekken op ChatPlaza.
                </p>
              </div>
            </section>

            <div className="content-section">
              <div className="content-section-text">
                <h1>Voor elke interesse een chatbox</h1>
                <p>
                  Of je nu wilt chatten over muziek, sport, reizen of een ander onderwerp, bij ons vind je altijd de chatbox die bij jouw interesses past. Log in met je profiel en begin direct met chatten met anderen die dezelfde passie delen.
                </p><br />
                <p>
                  Zo maak je eenvoudig nieuwe contacten, start je boeiende gesprekken en kun je meteen chatten met je profiel. Ontdek de perfecte chatbox, ontmoet gelijkgestemden en geniet van eindeloos online chatplezier!
                </p>
              </div>
              <div className="hp-animation">
                <Lottie options={lottie1} height={400} width={400} />
              </div>
            </div>

            <div className="content-section">
              <div className='hp-animation image-first'>
                <Lottie options={lottie2} height={400} width={400} />
              </div>
              <div className="content-section-text">
                <h1>Waar vreemden vrienden worden</h1>
                <p>
                  Ben je op zoek naar nieuwe connecties en wil je graag chatten met vreemden? In onze chatboxen ontmoet je eenvoudig nieuwe mensen en kun je niet alleen samen chatten, maar ook persoonlijker contact zoeken via een privéchat.
                </p><br />
                <p>
                  Heb je een bijzondere klik via de chat? Start dan een privégesprek om elkaar beter te leren kennen. Voeg nieuwe chatvrienden direct toe aan je vriendenlijst, zodat je altijd snel weer met hen kunt chatten. Zo wordt chatten met vreemden de eerste stap naar duurzame vriendschappen!
                </p>
              </div>
              <div className='hp-animation image-second'>
                <Lottie options={lottie2} height={400} width={400} />
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default Homepage;