import Modal from 'anchor-ui/modal';
import './addGroupModal.css';
import Button from 'anchor-ui/button';
import { CometChat } from '@cometchat-pro/chat';
import { notifyMultipleUsersReload } from '../../ConversationSDK/friendRequests';
import { Functions } from 'firebase/functions';
import { ActiveChannelInterface } from '../../../interfaces/activeChannelInterface';

const DeleteMultipleConversationsModal = ({
  isOpen,
  closeModal,
  conversationsToDelete,
  setConversationsToDelete,
  setDeleteMultipleConversationsMode,
  refreshConversations,
  currentChat,
  setCurrentChat,
  setUnreadCount,
  loggedInUser,
  setAlert,
  functions,
}: {
  isOpen: boolean,
  closeModal: () => void,
  conversationsToDelete: any[],
  setConversationsToDelete: React.Dispatch<React.SetStateAction<any[]>>,
  setDeleteMultipleConversationsMode: (value: React.SetStateAction<boolean>) => void,
  refreshConversations: () => void,
  currentChat: ActiveChannelInterface,
  setCurrentChat: (chat: ActiveChannelInterface) => void,
  setUnreadCount: (value: React.SetStateAction<{ [id: string]: { nr: number, chat: ActiveChannelInterface } }>) => void,
  loggedInUser: CometChat.User,
  setAlert: (alert: { message: string, type: string }) => void,
  functions: Functions,
}) => {

  const handleClose = () => {
    closeModal();
  }

  const deleteGroup = async (conversation: any) => {
    // Get members of the group
    const guid = conversation.guid;
    let members: string[] = [];

    const groupMembersRequest = new CometChat.GroupMembersRequestBuilder(guid).setLimit(100).build();

    await groupMembersRequest.fetchNext().then(
      groupMembers => {
        members = groupMembers.map(member => member.getUid());
      },
      error => {
        console.error("Group member list fetching failed with error:", error);
      }
    );

    await CometChat.deleteGroup(guid).then(
      () => {
        if (conversation.guid === currentChat.id) {
          setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
        }
        notifyMultipleUsersReload(functions, members, 'group_deleted', conversation); // Notify all users to reload their group list
      },
      error => {
        console.error("Failed to delete group:", error);
      }
    );
  }

  const leaveGroup = async (conversation: any) => {
    try {
      await CometChat.leaveGroup(conversation.guid);

      if (conversation.guid === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }
    } catch (error) {
      console.error("Failed to leave group:", error);
    }
  };

  const handleLeaveGroup = async (conversation: any) => {
    const isGroupOwner = conversation.owner === loggedInUser.getUid();

    if (isGroupOwner) await deleteGroup(conversation);
    else await leaveGroup(conversation);
  }

  const deleteConversation = async (conversation: any) => {
    try {
      // Set all messages as read
      const lastMessageId = conversation.lastMessage?.id;
      const senderId = conversation.conversationWith.uid;
      const receiverType = conversation.conversationType;

      if (lastMessageId) await CometChat.markAsRead(lastMessageId, loggedInUser.getUid(), receiverType, senderId);

      // Remove the conversation from the unread count
      setUnreadCount(prevState => {
        const newState = { ...prevState };
        delete newState[conversation.conversationId];
        return newState;
      });

      // Delete the conversation
      await CometChat.deleteConversation(senderId, receiverType)
        .catch(error => {
          if (error.code !== 'ERR_CONVERSATION_NOT_ACCESSIBLE') {
            throw error;
          }
        })

      if (conversation.conversationId === currentChat.id) {
        setCurrentChat({ id: '', name: '', icon: '', joinedAt: 0, isGroup: false });
      }
    } catch (error) {
      console.error("Error marking messages as read or deleting conversation:", error);
    }
  };

  const handleRemoveConversation = async (conversation: any) => {
    const isGroup = conversation.guid;

    if (isGroup) await handleLeaveGroup(conversation);
    else await deleteConversation(conversation);
  }

  const handleRemoveSelectedConversations = async () => {
    try {
      // Create a promise for each conversation to delete
      const deletePromises = conversationsToDelete.map(conversation => handleRemoveConversation(conversation));
  
      // Wait for all deletion promises to resolve
      await Promise.all(deletePromises);
  
      setConversationsToDelete([]);
      setDeleteMultipleConversationsMode(false);

      setAlert({ message: 'Gesprekken zijn verwijderd', type: 'success' });
      refreshConversations();
      closeModal();
    } catch (e) {
      setAlert({ message: 'Er is iets fout gegaan bij het verwijderen van het gesprek', type: 'error' });
    }
  };

  return (
    <Modal
      open={isOpen}
      header={
        <span>Verwijder {conversationsToDelete.length} gesprekken</span>
      }
      actions={
        <>
          <Button flatButton onClick={handleClose}>ANNULEER</Button>

          <Button flatButton onClick={handleRemoveSelectedConversations}>SLUIT</Button>

        </>
      }
      headerStyle={{ fontSize: '16px' }}
    >
      Weet je zeker dat je {conversationsToDelete.length} gesprekken wilt sluiten? Hiermee wordt ook de geschiedenis van elk kanaal gewist.
    </Modal>
  );
}

export default DeleteMultipleConversationsModal;
