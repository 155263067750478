import React, { useEffect, useState, useRef, useCallback } from 'react';
import { CometChat } from '@cometchat-pro/chat';
import { ActiveChannelInterface } from './../../interfaces/activeChannelInterface';
import './styles.css';
import MessageComponent from './MessageComponent';
import MessageListComponent from './MessageListComponent';
import MessageInputComponent from './MessageInputComponent';
import ChannelHeaderComponent from './ChannelHeader';
import { getDateFromTimestamp } from '../getDateFromTimestamp';
import { NotificationInterface } from './../../interfaces/notificationInterface';
import EditMessageModal from './EditMessageModal';
import ReportMessageModal from './../../components/ReportModal/ReportMessageModal';
import { checkIfUserIsBlocked } from '../../utils/checkIfUserIsBlocked';
import Message from 'anchor-ui/message';
import chatBotImg from './../../assets/chatbot.jpg';
import AdminBadge from 'anchor-ui/admin-badge';
import Button from 'anchor-ui/button';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { ReplyMessageInterface } from '../../interfaces/replyMessageInterface';
import { ConversationInterface } from '../../interfaces/conversationInterface';
import { isGroupConversation } from '../../utils/isGroupConversationCheck';
import GoogleAdSense from '../GoogleAdSense';
import { checkIfUserHasPermissions, checkIfUserIsAdmin, checkIfUserIsModerator } from '../../utils/checkIfUserIsAdmin';

interface ChatComponentProps {
  currentChat: ActiveChannelInterface;
  setCurrentChat: React.Dispatch<React.SetStateAction<ActiveChannelInterface>>;
  conversations: any;
  setConversations: (conversation: any) => void;
  loggedInUser: CometChat.User | null;
  loadIncomingFriendRequests: () => void;
  loadOutgoingFriendRequests: () => void;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationInterface[] | null>>;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  friends: CometChat.User[];
  hasSentFriendRequest: (user: CometChat.User) => boolean;
  hasReceivedFriendRequest: (user: CometChat.User) => boolean;
  handleAddOrRemoveFriend: (friend: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User, guid?: string } | null) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  messages: CometChat.BaseMessage[];
  setMessages: React.Dispatch<React.SetStateAction<CometChat.BaseMessage[]>>;
  leftMenuOpen: boolean;
  setLeftMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rightMenuOpen: boolean;
  setRightMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unreadCount: { [id: string]: { nr: number, chat: ActiveChannelInterface } };
  groupMembers?: CometChat.GroupMember[];
  chatWithUser?: CometChat.User;
  showLoadMostRecentMsgBtn: boolean;
  setShowLoadMostRecentMsgBtn: React.Dispatch<React.SetStateAction<boolean>>;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleDeleteMessageAsAdmin: (message: CometChat.BaseMessage) => void;
  userSettings: UserSettingsInterface;
  setRemoveConversation: React.Dispatch<React.SetStateAction<any | null>>;
  setUserToWarn: (user: CometChat.User) => void;
  setMessageToForward: (message: CometChat.BaseMessage) => void;
  handleSendImage: (event: React.ChangeEvent<HTMLInputElement>, receiver: string, isGroup: boolean, isForwarded?: boolean) => void;

  messageInput: string;
  setMessageInput: React.Dispatch<React.SetStateAction<string>>;
  commandMenuOpen: boolean;
  setCommandMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  messageToReply: ReplyMessageInterface | null;
  setMessageToReply: React.Dispatch<React.SetStateAction<ReplyMessageInterface | null>>;
  handleSendMessage: (event: any, receiver: string, isGroup: boolean, messageText?: string) => void;
  isFetching: boolean;
  fetchMessages: () => void;
  buildMessagesRequest: (fetchFromTimestamp: number, limit: number, currentChat: any) => CometChat.MessagesRequest;

  showLoadMoreBtn: boolean;
  setShowLoadMoreBtn: React.Dispatch<React.SetStateAction<boolean>>;

  filterValidMessages: (timestamp: number, messageList: CometChat.BaseMessage[], messages: CometChat.BaseMessage[], currentChat: any, checkDuplicate?: boolean) => CometChat.BaseMessage[];
  setActiveTabIndex: (index: number) => void;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  currentChat,
  setCurrentChat,
  conversations,
  setConversations,
  loggedInUser,
  loadIncomingFriendRequests,
  loadOutgoingFriendRequests,
  blockedUsers,
  handleBlockUser,
  friends,
  hasSentFriendRequest,
  hasReceivedFriendRequest,
  handleAddOrRemoveFriend,
  setUserToReport,
  setAlert,

  messages,
  setMessages,

  leftMenuOpen,
  setLeftMenuOpen,
  rightMenuOpen,
  setRightMenuOpen,
  unreadCount,

  groupMembers,
  chatWithUser,

  showLoadMostRecentMsgBtn,
  setShowLoadMostRecentMsgBtn,

  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,
  handleDeleteMessageAsAdmin,

  userSettings,

  setRemoveConversation,
  setUserToWarn,
  setMessageToForward,

  messageInput,
  setMessageInput,
  commandMenuOpen,
  setCommandMenuOpen,
  messageToReply,
  setMessageToReply,
  handleSendMessage,
  handleSendImage,

  isFetching,
  fetchMessages,
  buildMessagesRequest,

  showLoadMoreBtn,
  setShowLoadMoreBtn,

  filterValidMessages,
  setActiveTabIndex,
}) => {
  const [editMessage, setEditMessage] = useState<CometChat.TextMessage | null>(null);
  const [messageToReport, setMessageToReport] = useState<CometChat.BaseMessage | null>(null);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const messageEndRef = useRef<HTMLDivElement | null>(null);

  const loadIncomingFriendRequestsRef = useRef(loadIncomingFriendRequests);
  const loadOutgoingFriendRequestsRef = useRef(loadOutgoingFriendRequests);

  const publicChannelIds = ["admin_chat", "lesbox"];

  const handleMessageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageInput(event.target.value);
  };

  // Fetch older messages for the current chat
  const fetchOlderMessages = async () => {
    const fetchFromTimestamp = messages[0]?.getSentAt() ?? 0;
    const scrollToId = messages[0]?.getId() + '';
    let messagesRequest = buildMessagesRequest(fetchFromTimestamp, 50, currentChat);

    try {
      const messageList = await messagesRequest.fetchPrevious();
      if (messageList.length < 50) setShowLoadMoreBtn(false);

      const validMessages = filterValidMessages(0, messageList, messages, currentChat, true);
      const updatedMessages = [...validMessages, ...messages];

      // If the updatedMessages array is over 150 messages, remove the last 50 messages
      if (updatedMessages.length > 150) {
        updatedMessages.splice(150, 50); // Remove older messages
        setShowLoadMostRecentMsgBtn(true);
      }

      setMessages(updatedMessages);

      // Scroll to the previous oldest message fetched
      const previousOldestMsg = document.getElementById(scrollToId);
      if (previousOldestMsg) previousOldestMsg.scrollIntoView();
    } catch (error) {
      console.error('Message fetching failed with error:', error);
      setAlert({ message: 'Berichten konden niet worden opgehaald', type: 'error' });
    }
  };

  useEffect(() => {
    loadIncomingFriendRequestsRef.current = loadIncomingFriendRequests;
    loadOutgoingFriendRequestsRef.current = loadOutgoingFriendRequests;
  }, [loadIncomingFriendRequests, loadOutgoingFriendRequests]);

  const handleDeleteMessage = (messageId: string) => {
    CometChat.deleteMessage(messageId).then(
      () => {
        setMessages(prevMessages => prevMessages.filter(message => message.getId() + '' !== messageId));
      },
      error => {
        console.error('Message deleting failed with error:', error);
        setAlert({ message: 'Bericht kon niet worden verwijderd', type: 'error' });
      }
    );
  }

  const getConversationFromMessage = (message: CometChat.BaseMessage) => {
    return conversations.find((conversation: ConversationInterface) => {
      if (isGroupConversation(conversation)) return false;
      const privateConversation = conversation.conversation as CometChat.Conversation;
      return privateConversation.getConversationId() === message.getReceiverId();
    });
  }

  useEffect(() => {
    // Fetch messages for the current chat when the chat is opened
    fetchMessages();

    const listenerId = 'message-listener';

    CometChat.addMessageListener(
      listenerId,
      new CometChat.MessageListener({
        onTextMessageReceived: (message: CometChat.TextMessage) => {
          const senderUid = message.getSender().getUid();

          if (
            (message.getReceiverId() === currentChat.id ||
              message.getConversationId() === currentChat.id)
            && !showLoadMostRecentMsgBtn && !checkIfUserIsBlocked(blockedUsers, senderUid)
          ) {
            CometChat.markAsRead(message);
            setMessages(prevMessages => [...prevMessages, message]);
          }

          // Get the conversation and update the last message
          const conversation = getConversationFromMessage(message);

          // Update the last message in the conversation
          if (conversation) {
            conversation.lastMsg = message.getText();
            conversation.isMyMsg = message.getSender().getUid() === loggedInUser?.getUid();
          }
        },
        onMediaMessageReceived: (message: CometChat.BaseMessage) => {
          const senderUid = message.getSender().getUid();

          if ((message.getReceiverId() === currentChat.id ||
            message.getConversationId() === currentChat.id)
            && !showLoadMostRecentMsgBtn && !checkIfUserIsBlocked(blockedUsers, senderUid)
          ) {
            CometChat.markAsRead(message);

            if (!showLoadMostRecentMsgBtn) setMessages(prevMessages => [...prevMessages, message]);
          }

          // Get the conversation and update the last message
          const conversation = getConversationFromMessage(message);

          // Update the last message in the conversation
          if (conversation) {
            conversation.lastMsg = 'Afbeelding';
            conversation.isMyMsg = message.getSender().getUid() === loggedInUser?.getUid();
          }
        },
        onCustomMessageReceived: (message: CometChat.CustomMessage) => {
          let tags = message.getTags();
          if (!tags) tags = (message.getCustomData() as any).tags;

          if (tags) {
            if (tags.includes('friend_request') || tags.includes('reload_requests')) {
              loadIncomingFriendRequestsRef.current();
              return;
            }

            if (tags.includes('reload_requests')) {
              loadOutgoingFriendRequestsRef.current();
              return;
            }
          }

          if (message.getType() === 'extension_poll' && !showLoadMostRecentMsgBtn) {
            setMessages(prevMessages => [...prevMessages, message]);
          }
        },
        onMessageDeleted: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => prevMessages.filter(msg => msg.getId() !== message.getId()));
        },
        onMessageEdited: (message: CometChat.BaseMessage) => {
          setMessages(prevMessages => {
            const updatedMessages = prevMessages.map(msg => {
              if (msg.getId() === message.getId()) {
                return message;
              }
              return msg;
            });
            return updatedMessages;
          });
        },
      })
    );

    return () => {
      CometChat.removeMessageListener(listenerId);
    };
  }, [currentChat]);

  const checkIfMessageContainsInvisibileChars = (message: CometChat.BaseMessage) => {
    if (!(message instanceof CometChat.TextMessage)) return false;
    const textMessage = message as CometChat.TextMessage;
    const invisibleCharacterRegex = /[\u200B\u200C\u200D\u200E\u200F\u202A-\u202E\u2060\uFEFF\u00A0]/;
    return invisibleCharacterRegex.test(textMessage.getText());
  }

  return (
    <div className="chat-component">
      <EditMessageModal
        editMessage={editMessage}
        setEditMessage={setEditMessage}
        setMessages={setMessages}
        setAlert={setAlert}
      />
      <ReportMessageModal
        messageToReport={messageToReport}
        setMessageToReport={setMessageToReport}
        setAlert={setAlert}
      />

      <ChannelHeaderComponent
        blockedUsers={blockedUsers}
        handleBlockUser={handleBlockUser}
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        friends={friends}
        hasSentFriendRequest={hasSentFriendRequest}
        hasReceivedFriendRequest={hasReceivedFriendRequest}
        handleAddOrRemoveFriend={handleAddOrRemoveFriend}
        setUserToReport={setUserToReport}
        leftMenuOpen={leftMenuOpen}
        setLeftMenuOpen={setLeftMenuOpen}
        rightMenuOpen={rightMenuOpen}
        setRightMenuOpen={setRightMenuOpen}
        unreadCount={unreadCount}
        userSettings={userSettings}
        setRemoveConversation={setRemoveConversation}
        loggedInUserIsAdmin={checkIfUserHasPermissions(loggedInUser!)}
      />

      <div className={`message-list ${emojiMenuOpen ? 'emoji-padding' : commandMenuOpen ? 'command-padding' : messageToReply ? 'reply-padding' : ''}`}>

        <GoogleAdSense
          adSlot="8285598752"
          className="chat-top-rectangle"
        />

        <MessageListComponent>

          {showLoadMoreBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchOlderMessages}
              style={{ marginTop: "150px"}}
            >
              Laad Meer Berichten
            </Button>
          )}

          {publicChannelIds.includes(currentChat.id) && !isFetching && (
            <Message
              avatar={chatBotImg}
              body={
                currentChat.id === "admin_chat" ? (
                  <span className='chatbot-msg'>
                    <b>Let op:</b> Deze chatroom is uitsluitend bedoeld voor het stellen van vragen over problemen of ondersteuning. Gebruik deze ruimte niet voor gewone gesprekken. Bedankt voor je begrip!
                  </span>
                ) : (
                  <span className='chatbot-msg'>
                    🎉 Welkom in de chatroom Lesbox! 🌸<br />
                    Deze ruimte is speciaal voor vrouwen om in een veilige en respectvolle omgeving te praten. <br />
                    <br />
                    ⚠️ <b>Huisregels:</b><br />
                    Geen 18+ berichten of inhoud.<br />
                    Respecteer elkaar en houd het gezellig.<br />
                    Voel je vrij om vragen te stellen of gesprekken te starten! 😊<br />
                    Veel plezier en laten we er samen iets moois van maken! 🌈<br />
                  </span>
                )
              }
              createdAt={
                <span className='chatbot-msg'>
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </span>
              }
              username={
                <>
                  <span>ChatBot </span>
                  <AdminBadge text="bot" />
                </>
              }
            />
          )}

          {/* {(currentChat.showPinnedMessages ? pinnedMessages : messages) */}
          {messages.map((message, index) => (
            checkIfMessageContainsInvisibileChars(message) ? null : (
              <MessageComponent
                key={message.getId()}
                message={message}
                myMessage={loggedInUser !== null && message.getSender().getUid() === loggedInUser.getUid()}
                showMessageSeparator={index === 0 || getDateFromTimestamp(message.getSentAt()) !== getDateFromTimestamp((messages)[index - 1].getSentAt())}
                isBlocked={checkIfUserIsBlocked(blockedUsers, message.getSender().getUid())}
                handleDeleteMessage={handleDeleteMessage}
                setEditMessage={setEditMessage}
                setMessageToReport={setMessageToReport}
                handleBlockUser={handleBlockUser}
                groupMembers={groupMembers}
                loggedInUser={loggedInUser ?? undefined}
                setCurrentChat={setCurrentChat}
                setAlert={setAlert}

                isAdmin={checkIfUserIsAdmin(message.getSender())}
                isModerator={checkIfUserIsModerator(message.getSender())}
                loggedInUserIsAdmin={checkIfUserIsAdmin(loggedInUser!)}
                loggedInUserIsModerator={checkIfUserIsModerator(loggedInUser!)}

                handleKickUser={handleKickUser}
                handleLogoutKickUser={handleLogoutKickUser}
                handleBanUser={handleBanUser}
                handleDeleteMessageAsAdmin={handleDeleteMessageAsAdmin}
                currentChat={currentChat}
                userSettings={userSettings}
                setMessageToReply={setMessageToReply}
                setUserToWarn={setUserToWarn}
                setMessageToForward={setMessageToForward}
                setActiveTabIndex={setActiveTabIndex}
              />
            )))}
          {/* Display a message from the ChatBot if the chat is a private chat and the user has not sent any messages yet: */}
          {(!currentChat.isGroup && chatWithUser) && messages.length === 0 && !isFetching && (
            <Message
              avatar={chatBotImg}
              body={
                <span className='chatbot-msg'>
                  Deel nooit gegevens met vreemden en open nooit onbekende urls. ChatPlaza en moderators zullen nooit vragen naar accountgegevens of vragen om betalingen te doen. Kom je iets verdachts tegen? Rapporteer het bericht en/of de gebruiker.
                </span>
              }
              createdAt={
                <span className='chatbot-msg'>
                  {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                </span>
              }
              username={
                <>
                  <span>ChatBot </span>
                  <AdminBadge text="bot" />
                  <small> Alleen zichtbaar voor jou</small>
                </>
              }
            />
          )}

          {showLoadMostRecentMsgBtn && messages.length > 0 && (
            <Button
              inverted
              onClick={fetchMessages}
            >
              Laad Recente Berichten
            </Button>
          )}
          <div ref={messageEndRef} />
        </MessageListComponent>
      </div>

      <MessageInputComponent
        handleChange={handleMessageInputChange}
        value={messageInput}
        handleSendMessage={handleSendMessage}
        handleSendImage={handleSendImage}
        groupMembers={groupMembers}
        chatWithUser={chatWithUser}

        emojiMenuOpen={emojiMenuOpen}
        setEmojiMenuOpen={setEmojiMenuOpen}
        setCommandMenuOpen={setCommandMenuOpen}
        currentChat={currentChat}
        userSettings={userSettings}

        messageToReply={messageToReply}
        setMessageToReply={setMessageToReply}
      />
    </div>
  );
};

export default ChatComponent; 