import { CometChat } from "@cometchat-pro/chat";
import { IconClose } from "anchor-ui/icons";

const RemoveConversationButton = ({
  conversation,
  setRemoveConversation,
}: {
  conversation: CometChat.Conversation | CometChat.Group,
  setRemoveConversation: React.Dispatch<React.SetStateAction<CometChat.Conversation | CometChat.Group | null>>,
}) => {
  const handleOpenRemoveConversationModal = (conversation: CometChat.Conversation | CometChat.Group, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    setRemoveConversation(conversation);
  };

  return (
    <div data-radium="true" style={{ position: 'relative' }}>
      <div>
        <button
          type="button"
          data-radium="true"
          className='li-button'
          onClick={(event) => handleOpenRemoveConversationModal(conversation, event)}
        >
          <IconClose />
        </button>
      </div>
    </div>
  );
};

export default RemoveConversationButton;